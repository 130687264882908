import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Game, Round, Player, GameState } from 'projects/models/src/public-api';
import { AuthService } from './auth.service';
import { Logger, LogService } from './log.service';
import { GameSettingsService } from './game-settings.service';

@Injectable({
  providedIn: 'root',
})
export class GameStateService {
  private unsubscribe = new Subject<void>();

  private readonly game = new BehaviorSubject<Game>(new Game());
  private readonly gamestate = new BehaviorSubject<GameState>(GameState.BROWSE);
  private readonly currentPlayer = new BehaviorSubject<Player>(new Player());

  private log: Logger;

  constructor(
    private authService: AuthService,
    private logService: LogService,
    private gameSettingsService: GameSettingsService
  ) {
    this.log = this.logService.forComponent('service:game-state');

    // create initial Game and CurrentPlayer
    if (!this.getGame().id) {
      const newGame = this.createGame();
      this.setGame(newGame);
    }

    if (!this.getCurrentPlayer().id) {
      const newCurrentPlayer = this.createCurrentPlayer();
      this.setCurrentPlayer(newCurrentPlayer);
    }

    // change username when logged in
    this.authService
      .getCurrentUserObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((newUser) => {
        if (newUser) {
          this.changeUsername(newUser.username);
        }
      });
  }

  // GETTER/SETTER
  setCurrentPlayer(player: Player): void {
    this.currentPlayer.next(player);
  }

  getCurrentPlayer(): Player {
    return this.currentPlayer.getValue();
  }

  getCurrentPlayerObservable(): Observable<Player> {
    return this.currentPlayer;
  }

  setGame(game: Game): void {
    this.game.next(game);
    this.updateGameStateFromGame();
    this.updateCurrentPlayerFromGame();
  }

  getGame(): Game {
    return this.game.getValue();
  }

  getGameObservable(): Observable<Game> {
    return this.game;
  }

  getGameStateObservable(): Observable<GameState> {
    return this.gamestate;
  }

  getRound(): Round {
    return this.getGame().round;
  }

  isCurrentPlayer(player: Player): boolean {
    return this.getCurrentPlayer().id === player.id;
  }

  isInGame(): boolean {
    return this.getGame().id != null;
  }

  getHighestPlayer(): Player {
    // players are ordered
    return this.getGame()?.players?.[0];
  }

  areAllOtherPlayersReady(): boolean {
    if (this.getGame()?.players.length == 0) {
      return false;
    }
    for (let player of this.getGame()?.players) {
      if (player.ready === false && !this.isCurrentPlayer(player)) {
        return false;
      }
    }
    return true;
  }

  reset() {
    this.log.debug('resetting gamestate');

    const newGame = this.createGame();
    newGame.config = this.getGame().config;
    this.setGame(newGame);
  }

  private createGame(): Game {
    const game = new Game();
    game.running = false;
    game.config = this.gameSettingsService.getSettings().gameConfig;

    game.round = new Round();
    game.round.running = false;

    return game;
  }

  private createCurrentPlayer(): Player {
    const player = new Player();
    player.ready = false;
    player.score = 0;
    return player;
  }

  private changeUsername(name: string) {
    const currentPlayer = this.getCurrentPlayer();
    currentPlayer.name = name;
    this.setCurrentPlayer(currentPlayer);
  }

  private updateCurrentPlayerFromGame(): void {
    if (this.getGame().id && this.getGame().players) {
      let newCurrentPlayer = this.getGame().players.filter(
        (p) => p.id == this.getCurrentPlayer().id
      );
      if (newCurrentPlayer.length == 1) {
        this.setCurrentPlayer(newCurrentPlayer[0]);
      }
    }
  }

  private updateGameStateFromGame(): void {
    if (this.getGame().id) {
      if (this.getGame().running) {
        this.gamestate.next(GameState.GAME);
      } else {
        this.gamestate.next(GameState.LOBBY);
      }
    } else {
      this.gamestate.next(GameState.BROWSE);
    }
  }
}
