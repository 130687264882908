import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-question-countdown',
  templateUrl: './question-countdown.component.html',
  styleUrls: ['./question-countdown.component.scss'],
})
export class QuestionCountdownComponent {
  @Input() time: number;
  @Input() countdown: number;

  @ViewChild('t', { static: false, read: ElementRef }) timeElement: ElementRef;
  @ViewChild('svg', { static: false, read: ElementRef }) svgElement: ElementRef;

  constructor(private animationController: AnimationController) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.svgElement && changes.time) {
      if (changes.time.currentValue > 0) {
        this.animateCountdown(this.time / this.countdown, 500);

        if (changes.time.currentValue <= 5) {
          this.animateEffect();
        }
      } else {
        this.animateCountdown(0, 500);
      }
    }
  }

  private animateCountdown(percent: number, duration: number) {
    const circle = this.svgElement.nativeElement.querySelector('circle');
    const length = circle.getTotalLength();

    circle.style.strokeDasharray = length;

    const animation = this.animationController
      .create('tick')
      .addElement(circle)
      .duration(duration)
      .easing('ease-out')
      .keyframes([{ offset: 1, strokeDashoffset: length - length * percent }]);
    animation.play();
  }

  private animateEffect() {
    const animation = this.animationController
      .create('scale')
      .addElement(this.timeElement.nativeElement)
      .duration(200)
      .easing('ease-out')
      .keyframes([
        { offset: 0.5, transform: 'scale(1.8)' },
        { offset: 1, transform: 'scale(1)' },
      ]);
    animation.play();
  }
}
