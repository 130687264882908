import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-joker-badge',
  templateUrl: './joker-badge.component.html',
  styleUrls: ['./joker-badge.component.scss'],
})
export class JokerBadgeComponent {
  @Input() icon: string;

  constructor() {}
}
