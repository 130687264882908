<div class="joker-modal" #modal>
  <div class="joker-content" #canvas>
    <ng-content></ng-content>
  </div>
  <svg
    class="stripes"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1003.97 1009.5"
  >
    <defs>
      <style>
        .cls-1 {
          fill-rule: evenodd;
          fill: url(#radial-gradient);
        }
      </style>
      <radialGradient
        id="radial-gradient"
        cx="615.23"
        cy="557.75"
        r="503.37"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="1" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </radialGradient>
    </defs>
    <title>radial-stripes</title>
    <path
      class="cls-1"
      d="M615.23,53V557.75L510.29,64ZM318.54,149.4,615.23,557.75,409.93,96.64Zm-140.44,156L615.23,557.75,240.13,220ZM113.24,505l502,52.76-480-156Zm21.94,208.74,480.05-156-502,52.76Zm105,181.76,375.1-337.74L178.1,810.13Zm169.8,123.37,205.3-461.11L318.54,966.1Zm205.3,43.64V557.75L510.29,1051.47Zm205.3-43.64L615.23,557.75l104.94,493.72Zm169.8-123.37L615.23,557.75,911.91,966.1Zm104.94-181.76-480-156,437.13,252.38ZM1117.21,505l-502,52.76,502,52.76Zm-64.85-199.62L615.23,557.75l480-156Zm-140.45-156L615.23,557.75,990.33,220ZM720.17,64,615.23,557.75,820.53,96.64Z"
      transform="translate(-113.24 -53)"
    />
  </svg>
</div>
