import { Component, OnInit } from '@angular/core';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.page.html',
  styleUrls: ['./thanks.page.scss'],
})
export class ThanksPage implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit() {}

  async dismissModal() {
    this.modalService.dismissModal();
  }
}
