import { Joker } from './joker.model';
import { Question } from './question.model';

export class Round {
  id: number;
  running: boolean;
  beginTime: string;
  endTime: string;
  question: Question;
  modifiers?: Modifiers;
  jokers?: Array<Joker>;
  scores?: Array<PlayerScore>;
}

export class Modifiers {
  scores?: boolean;
  minusscore?: boolean;
  doublescore?: boolean;
}

export class PlayerScore {
  player: number;
  score: number;
}
