import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { GameState, User } from 'projects/models/src/public-api';
import {
  AuthService,
  GameService,
  GameStateService,
  MessageService,
} from 'projects/services/src/public-api';
import { ModalService } from '../services/modal.service';
import { FeedbackPage } from '../feedback/feedback.page';
import { LoginPage } from '../login/login.page';
import { RegisterPage } from '../register/register.page';
import { ThanksPage } from '../thanks/thanks.page';

@Component({
  selector: 'app-browse-header',
  templateUrl: './browse-header.component.html',
  styleUrls: ['./browse-header.component.scss'],
})
export class BrowseHeaderComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();

  isLoggedIn: boolean = false;
  isInLobby: boolean = false;
  user: User = new User();
  version: string = environment.version;

  constructor(
    private menu: MenuController,
    private router: Router,
    public gameStateService: GameStateService,
    private gameService: GameService,
    private messageService: MessageService,
    private modalService: ModalService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService
      .getAuthenticatedObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((authenticated) => {
        this.isLoggedIn = authenticated;
      });
    this.authService
      .getCurrentUserObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((newUser) => {
        if (newUser) {
          this.user = newUser;
        }
      });

    let gameState$ = this.gameStateService
      .getGameStateObservable()
      .pipe(takeUntil(this.unsubscribe));
    let route$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );
    combineLatest([gameState$, route$]).subscribe(([gameState, route]) => {
      if (GameState.LOBBY == gameState && !route?.url?.startsWith('/game-box')) {
        this.isInLobby = true;
      } else {
        this.isInLobby = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggleMenu() {
    this.menu.toggle('navigation');
  }

  openProfileOrLogin() {
    if (this.authService.isAuthenticated()) {
      this.go2Profile();
    } else {
      this.menu.close('navigation');
      this.presentRegisterModal();
    }
  }

  openBrowserLink(url: string) {
    Browser.open({ url: url });
  }

  go2Profile() {
    this.router.navigate(['profile']);
  }

  go2Home() {
    this.router.navigate(['home']);
  }

  go2Lobby() {
    this.router.navigate(['game-box'], {
      queryParams: { gameId: this.gameStateService.getGame().id },
    });
  }

  logout() {
    if (this.gameStateService.isInGame()) {
      this.messageService.presentAlert('Leave the current game?').then((answer) => {
        if (answer) {
          this.authService.logout();
          this.gameService.leave();
          this.go2Home();
        }
      });
    } else {
      this.authService.logout();
      this.go2Home();
    }
  }

  presentLoginModal() {
    this.modalService.presentModal(LoginPage);
  }

  presentRegisterModal() {
    this.modalService.presentModal(RegisterPage);
  }

  presentFeedbackModal() {
    this.modalService.presentModal(FeedbackPage);
  }

  presentThanksModal() {
    this.modalService.presentModal(ThanksPage);
  }
}
