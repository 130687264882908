import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Subject, Observable, combineLatest } from 'rxjs';

import {
  Answer,
  Answered,
  Begin,
  Clientplatform,
  Configured,
  Emojied,
  End,
  Fault,
  Finished,
  GameConfig,
  Joined,
  Joker,
  Jokered,
  Left,
  Readied,
  Rejoined,
  Rejoininfo,
  Spectated,
  Started,
  Unspectated,
} from 'projects/models/src/public-api';
import { environment } from 'src/environments/environment';
import { GameStateService } from './game-state.service';
import { MessageService } from './message.service';
import { Logger, LogService } from './log.service';
import { GameSocketService } from './game-socket.service';
import { AuthService } from './auth.service';
import { PwaService } from './pwa.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private joinProvider: Function = () => {
    return Promise.resolve(true);
  };
  private beforeJoinProvider: Function = (rejoin: Rejoininfo) => {
    return Promise.resolve(true);
  };

  private readonly readied: Subject<Readied> = new Subject();
  private readonly joined: Subject<Joined> = new Subject();
  private readonly rejoined: Subject<Rejoined> = new Subject();
  private readonly left: Subject<Left> = new Subject();
  private readonly spectated: Subject<Spectated> = new Subject();
  private readonly unspectated: Subject<Unspectated> = new Subject();

  private readonly started: Subject<Started> = new Subject();
  private readonly finished: Subject<Finished> = new Subject();
  private readonly configured: Subject<Configured> = new Subject();

  private readonly begin: Subject<Begin> = new Subject();
  private readonly end: Subject<End> = new Subject();
  private readonly answered: Subject<Answered> = new Subject();
  private readonly jokered: Subject<Jokered> = new Subject();
  private readonly emojied: Subject<Emojied> = new Subject();

  private log: Logger;

  constructor(
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private authService: AuthService,
    private gameSocketService: GameSocketService,
    private gameStateService: GameStateService,
    private logService: LogService,
    private messageService: MessageService,
    private pwaService: PwaService
  ) {
    this.log = this.logService.forComponent('service:game');

    this.bindSocket();

    // authenticate
    let authenticated = this.authService.getAuthenticatedObservable();
    let connected = this.gameSocketService.getConnectedObservable();
    combineLatest([authenticated, connected]).subscribe(([authenticated, connected]) => {
      if (authenticated && connected) {
        this.doAuthenticate(this.authService.getCurrentUser().token);
      }
    });

    // update
    this.pwaService.getUpdatingObservable().subscribe((updating) => {
      if (updating == true) {
        this.gameStateService.reset();
        this.router.navigate(['update']);
      }
    });

    // offline
    this.gameSocketService.getReconnectingObservable().subscribe((reconnecting) => {
      if (reconnecting == true) {
        this.gameStateService.reset();
        this.router.navigate(['offline']);
      }
    });
  }

  private bindSocket(): void {
    this.gameSocketService.getSocket().on('connected', async (data: any) => {
      this.log.debug('socket connected', data);

      this.gameStateService.getGame().config = data.game.config;
      this.gameStateService.setGame(this.gameStateService.getGame());
      this.gameStateService.getCurrentPlayer().id = data.player.id;
      this.gameStateService.setCurrentPlayer(this.gameStateService.getCurrentPlayer());

      this.tryJoin();
    });

    this.gameSocketService.getSocket().on('readied', (data: any) => {
      this.log.debug('socket readied', data);
      if (data?.game && data?.player) {
        this.gameStateService.setGame(data.game);

        const readied = new Readied();
        readied.game = data.game;
        readied.player = data.player;
        this.readied.next(readied);
      }
    });
    this.gameSocketService.getSocket().on('joined', (data: any) => {
      this.log.debug('socket joined', data);
      if (data?.game && data?.player) {
        this.gameStateService.setGame(data.game);

        const joined = new Joined();
        joined.game = data.game;
        joined.player = data.player;
        this.joined.next(joined);
      }
    });
    this.gameSocketService.getSocket().on('spectated', (data: any) => {
      this.log.debug('socket spectated', data);
      if (data?.game && data?.player) {
        this.gameStateService.setGame(data.game);

        const spectated = new Spectated();
        spectated.game = data.game;
        spectated.player = data.player;
        this.spectated.next(spectated);
      }
    });
    this.gameSocketService.getSocket().on('configured', (data: any) => {
      this.log.debug('socket configured', data);
      if (data?.game) {
        this.gameStateService.setGame(data.game);
        this.configured.next(data.game.config);
      }
    });
    this.gameSocketService.getSocket().on('left', (data: any) => {
      this.log.debug('socket left', data);
      if (data?.game && data?.player) {
        if (this.gameStateService.isCurrentPlayer(data.player)) {
          this.gameStateService.reset();
        } else {
          this.gameStateService.setGame(data.game);
        }

        const left = new Left();
        left.game = data.game;
        left.player = data.player;
        this.left.next(left);
      }
    });
    this.gameSocketService.getSocket().on('unspectated', (data: any) => {
      this.log.debug('socket unspectated', data);
      if (data?.game && data?.player) {
        this.gameStateService.setGame(data.game);

        const unspectated = new Unspectated();
        unspectated.game = data.game;
        unspectated.player = data.player;
        this.unspectated.next(unspectated);
      }
    });

    this.gameSocketService.getSocket().on('started', (data: any) => {
      this.log.debug('socket started', data);
      if (data?.game) {
        this.gameStateService.setGame(data.game);
        const started = new Started();
        started.game = data.game;
        this.started.next(started);
      }
    });
    this.gameSocketService.getSocket().on('finished', (data: any) => {
      this.log.debug('socket finished', data);
      if (data?.game) {
        this.gameStateService.setGame(data.game);
        const finished = new Finished();
        finished.game = data.game;
        this.finished.next(finished);
      }
    });

    this.gameSocketService.getSocket().on('begin', (data: any) => {
      this.log.debug('socket begin', data);
      if (data?.game) {
        this.gameStateService.setGame(data.game);
        const begin = new Begin();
        begin.game = data.game;
        this.begin.next(begin);
      }
    });
    this.gameSocketService.getSocket().on('end', (data: any) => {
      this.log.debug('socket end', data);
      if (data?.game) {
        this.gameStateService.setGame(data.game);
        const end = new End();
        end.game = data.game;
        this.end.next(end);
      }
    });
    this.gameSocketService.getSocket().on('answered', (data: any) => {
      this.log.debug('socket answered', data);
      if (data?.game && data?.player) {
        this.gameStateService.setGame(data.game);
        const answered = new Answered();
        answered.game = data.game;
        answered.player = data.player;
        this.answered.next(answered);
      }
    });
    this.gameSocketService.getSocket().on('jokered', (data: any) => {
      this.log.debug('socket jokered', data);
      if (data?.game && data?.player && data?.joker) {
        this.gameStateService.setGame(data.game);
        const jokered = new Jokered();
        jokered.game = data.game;
        jokered.player = data.player;
        jokered.joker = data.joker;
        this.jokered.next(jokered);
      }
    });
    this.gameSocketService.getSocket().on('emojied', (data: any) => {
      this.log.debug('socket emojied', data);
      if (data?.player && data?.emoji) {
        const emojied = new Emojied();
        emojied.player = data.player;
        emojied.emoji = data.emoji;
        this.emojied.next(emojied);
      }
    });
  }

  host(gameConfig: GameConfig, playerName: string): Promise<Joined | Fault> {
    return new Promise<Joined | Fault>((resolve, reject) => {
      this.log.debug('host new game as ' + playerName);
      const gamePlatform = this.createGamePlatform();
      this.gameSocketService
        .getSocket()
        .emit('host', {
          name: playerName,
          platform: gamePlatform,
          config: gameConfig,
        })
        .once('joined', (data) => {
          if (data?.game && data?.player) {
            this.gameStateService.setCurrentPlayer(data.player);
            this.writeRejoininfo(data.game.id, data.player.id);

            const joined = new Joined();
            joined.game = data.game;
            joined.player = data.player;
            return resolve(joined);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  join(gameId: string, playerName?: string): Promise<Joined | Fault> {
    return new Promise<Joined | Fault>((resolve, reject) => {
      this.log.debug('join game ' + gameId + ' as ' + playerName);
      const gamePlatform = this.createGamePlatform();
      this.gameSocketService
        .getSocket()
        .emit('join', {
          name: playerName,
          game: gameId,
          platform: gamePlatform,
        })
        .once('joined', (data) => {
          if (data?.game && data?.player) {
            this.gameStateService.setCurrentPlayer(data.player);
            this.writeRejoininfo(data.game.id, data.player.id);

            const joined = new Joined();
            joined.game = data.game;
            joined.player = data.player;
            return resolve(joined);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  rejoin(playerId: string, gameId: string): Promise<Rejoined | Fault> {
    return new Promise<Rejoined | Fault>((resolve, reject) => {
      this.log.debug('rejoining game ' + gameId);
      const gamePlatform = this.createGamePlatform();
      this.gameSocketService
        .getSocket()
        .emit('rejoin', {
          player: playerId,
          game: gameId,
          platform: gamePlatform,
        })
        .once('rejoined', (data) => {
          if (data?.game && data?.player) {
            this.gameStateService.setCurrentPlayer(data.player);
            this.gameStateService.setGame(data.game);
            this.writeRejoininfo(data.game.id, data.player.id);

            const rejoined = new Rejoined();
            rejoined.game = data.game;
            rejoined.player = data.player;
            return resolve(rejoined);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  configure(name: String, config: GameConfig): Promise<Configured | Fault> {
    return new Promise<Configured | Fault>((resolve, reject) => {
      const gamePlatform = this.createGamePlatform();

      this.log.debug('configure', config);
      this.gameSocketService
        .getSocket()
        .emit('configure', {
          name: name,
          platform: gamePlatform,
          config: config,
        })
        .once('configured', (data) => {
          if (data?.player && data?.game) {
            this.gameStateService.setCurrentPlayer(data.player);
            this.gameStateService.setGame(data.game);

            const configured = new Configured();
            configured.game = data.game;
            configured.player = data.player;
            resolve(configured);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  start(): Promise<Started | Fault> {
    return new Promise<Started | Fault>((resolve, reject) => {
      this.log.debug('start game');
      this.gameSocketService
        .getSocket()
        .emit('start')
        .once('started', (data) => {
          if (data?.game) {
            const started = new Started();
            started.game = data.game;
            resolve(started);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            reject(fault);
          }
        });
    });
  }

  ready(ready: boolean): Promise<Readied> {
    return new Promise<Readied>((resolve, reject) => {
      this.log.debug('ready ' + ready);
      this.gameSocketService
        .getSocket()
        .emit('ready', { ready: ready })
        .once('readied', (data) => {
          if (data?.game && data?.player) {
            const readied = new Readied();
            readied.game = data.game;
            readied.player = data.player;
            resolve(readied);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  answer(answer: Answer): Promise<Answered | Fault> {
    return new Promise<Answered | Fault>((resolve, reject) => {
      this.log.debug('answer with id', answer.id);
      this.gameSocketService
        .getSocket()
        .emit('answer', { answer: answer.id })
        .once('answered', (data) => {
          if (data?.game && data?.player) {
            const answered = new Answered();
            answered.game = data.game;
            answered.player = data.player;
            resolve(answered);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  joker(joker: Joker): Promise<Jokered | Fault> {
    return new Promise<Jokered | Fault>((resolve, reject) => {
      this.log.debug('joker with name ', joker.id);
      this.gameSocketService
        .getSocket()
        .emit('joker', { joker: joker.id })
        .once('jokered', (data) => {
          if (data?.game && data?.player && data?.joker) {
            const jokered = new Jokered();
            jokered.game = data.game;
            jokered.player = data.player;
            jokered.joker = data.joker;
            resolve(jokered);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  replay(): Promise<Started | Fault> {
    return new Promise<Started | Fault>((resolve, reject) => {
      this.log.debug('replay game');
      this.gameSocketService
        .getSocket()
        .emit('replay')
        .once('started', (data) => {
          if (data?.game) {
            const started = new Started();
            started.game = data.game;
            resolve(started);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  emoji(codePoint: number): Promise<Emojied | Fault> {
    return new Promise<Emojied | Fault>((resolve, reject) => {
      this.log.debug('replay game');
      this.gameSocketService
        .getSocket()
        .emit('emoji', { emoji: codePoint })
        .once('emojied', (data) => {
          if (data?.player && data?.emoji) {
            const emojied = new Emojied();
            emojied.player = data.player;
            emojied.emoji = data.emoji;
            resolve(data);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  leave(): Promise<Left | Fault> {
    return new Promise<Left | Fault>((resolve, reject) => {
      this.log.debug('leave game');
      this.gameSocketService
        .getSocket()
        .emit('leave')
        .once('left', (data) => {
          if (data?.game && data?.player) {
            const left = new Left();
            left.game = data.game;
            left.player = data.player;
            resolve(left);
          } else {
            const fault = new Fault();
            fault.errorId = -1 || data?.errorId;
            fault.message = data?.message;
            return reject(fault);
          }
        });
    });
  }

  setJoinProvider(joinProvider: Function): void {
    this.joinProvider = joinProvider;
  }
  setBeforeJoinProvider(beforeJoinProvider: Function): void {
    this.beforeJoinProvider = beforeJoinProvider;
  }

  startedGame(): Observable<Started> {
    return this.started;
  }

  joinedGame(): Observable<Joined> {
    return this.joined;
  }

  rediedGame(): Observable<Readied> {
    return this.readied;
  }

  rejoinedGame(): Observable<Rejoined> {
    return this.rejoined;
  }

  configuredGame(): Observable<Configured> {
    return this.configured;
  }

  leftGame(): Observable<Left> {
    return this.left;
  }

  finishedGame(): Observable<Finished> {
    return this.finished;
  }

  beginRound(): Observable<Begin> {
    return this.begin;
  }

  endRound(): Observable<End> {
    return this.end;
  }

  answeredInGame(): Observable<Answered> {
    return this.answered;
  }

  jokeredInGame(): Observable<Jokered> {
    return this.jokered;
  }

  emojiReceived(): Observable<Emojied> {
    return this.emojied;
  }

  createGamePlatform(): Clientplatform {
    return new Clientplatform(
      this.platform.is('mobile'),
      this.platform.isLandscape(),
      this.platform.width(),
      this.platform.height()
    );
  }

  private doAuthenticate(token: String): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authService.isAuthenticated()) {
        this.log.debug('authenticate');
        this.gameSocketService
          .getSocket()
          .emit('authenticate', {
            token: token,
          })
          .once('authenticated', (data) => {
            if (data.player) {
              resolve(data);
            } else {
              reject(data);
            }
          });
      }
      // user not logged in
      else {
        resolve(false);
      }
    });
  }

  private tryJoin(): void {
    this.joinProvider().then((join) => {
      // first we try to reoin a left game
      if (environment.features.autorejoin) {
        this.readRejoininfo().then((rejoin) => {
          // we found some previous game
          if (rejoin) {
            this.beforeJoinProvider(rejoin).then((result) => {
              // user confirms (home)
              if (result && join === true) {
                this.doRejoin(rejoin);
                // user follows link (game-box)
              } else if (typeof join == 'string') {
                this.log.debug('join id', join);
                this.doJoin(join);
              } else {
                this.log.debug('no rejoin');
                this.gameStateService.reset();
              }
            });
          } else if (typeof join == 'string') {
            this.log.debug('join id', join);
            this.doJoin(join);
          } else {
            this.log.debug('no rejoin');
            this.gameStateService.reset();
          }
        });
      } else if (typeof join == 'string') {
        this.doJoin(join);
      }
    });
  }

  private doJoin(gameId: string): void {
    this.join(gameId, this.gameStateService.getCurrentPlayer().name).catch((fault) => {
      console.error('join not possible', fault);
      this.router.navigate(['home']);
      this.messageService.presentErrorToast('That did not work &#128533;');
    });
  }

  private doRejoin(rejoin: Rejoininfo): void {
    this.rejoin(rejoin.playerId, rejoin.gameId)
      .then((data) => {
        this.log.debug('socket rejoined', data);
        if (data instanceof Rejoined) {
          if (!data.game.finished) {
            this.router.navigate(['game-box'], {
              queryParams: { gameId: rejoin.gameId },
            });
          } else {
            this.router.navigate(['scoreboard'], {
              queryParams: { gameId: rejoin.gameId },
            });
          }
          this.rejoined.next(data);
        }
      })
      .catch((fault) => {
        console.error('rejoin not possible', fault);
        this.deleteRejoininfo();
        this.router.navigate(['home']);
        this.messageService.presentErrorToast('That did not work &#128533;');
      });
  }

  private readRejoininfo(): Promise<Rejoininfo> {
    return new Promise((resolve) => {
      this.storage
        .get('rejoin')
        .then((data) => {
          if (this.isTimestampYoungerFifteenMinutes(data?.timestamp)) {
            return resolve(data);
          }
          this.deleteRejoininfo();
          resolve(null);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  private deleteRejoininfo(): void {
    this.storage.remove('rejoin');
  }

  private writeRejoininfo(gameId: string, playerId: string): Promise<Rejoininfo> {
    return new Promise((resolve) => {
      const rejoininfo = new Rejoininfo();
      rejoininfo.gameId = gameId;
      rejoininfo.playerId = playerId;
      rejoininfo.timestamp = new Date().getTime();
      this.storage.set('rejoin', rejoininfo).then(resolve);
    });
  }

  private isTimestampYoungerFifteenMinutes(timestamp: number): boolean {
    const fifteenMinutes = 15 * 60 * 1000;
    const now = new Date().getTime();

    if (timestamp + fifteenMinutes >= now) {
      return true;
    }
    return false;
  }
}
