import { DOCUMENT } from '@angular/common';
import { ComponentFactoryResolver, Inject, Injectable, Injector, Renderer2 } from '@angular/core';
import { Joker } from 'projects/models/src/public-api';
import { JokerBadgeComponent } from '../joker-badge/joker-badge.component';
import { JokerEffectComponent } from './joker-effect.component';

@Injectable({
  providedIn: 'root',
})
export class JokerEffectService {
  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  show(joker: Joker) {
    const factory = this.resolver.resolveComponentFactory(JokerEffectComponent);

    const componentRef = factory.create(this.injector, this.resolve(joker));
    componentRef.changeDetectorRef.detectChanges();
    const { nativeElement } = componentRef.location;

    this.document.body.appendChild(nativeElement);

    componentRef.instance.show().then(() => {
      this.document.body.removeChild(nativeElement);
      componentRef.destroy();
    });
  }

  private resolve(joker: Joker) {
    let factory = this.resolver.resolveComponentFactory(JokerBadgeComponent);
    const componentRef = factory.create(this.injector);

    componentRef.instance.icon = joker.icon;
    componentRef.location.nativeElement.style.setProperty('--size', '100px');
    componentRef.location.nativeElement.style.setProperty('--color', 'red');

    componentRef.changeDetectorRef.detectChanges();
    return [[componentRef.location.nativeElement]];
  }
}
