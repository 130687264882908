<ion-content [fullscreen]="true">
  <header>
    <ion-toolbar>
      <ion-title>
        <ion-icon name="beer-outline"></ion-icon>
        <ion-label class="category-title">Thanks</ion-label>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </header>

  <section>
    <ion-card>
      <ion-card-content class="thanks-content">
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Question providers</ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label class="ion-text-wrap">Open Trivia Database</ion-label>
            <a href="https://opentdb.com/" target="_blank">opentdb.com</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">The Trivia Api</ion-label>
            <a href="https://the-trivia-api.com/" target="_blank">the-trivia-api.com</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">Thegamesdb</ion-label>
            <a href="https://thegamesdb.net/" target="_blank">thegamesdb.net</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">OpenLigaDB</ion-label>
            <a href="https://www.openligadb.de/" target="_blank">www.openligadb.de</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">IMDb</ion-label>
            <a href="https://www.imdb.com/" target="_blank">www.imdb.com</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">MovieStillsDB</ion-label>
            <a href="https://www.moviestillsdb.com/" target="_blank">www.moviestillsdb.com</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">Spotify</ion-label>
            <a href="https://www.spotify.com/" target="_blank">www.spotify.com</a>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">Restcountries</ion-label>
            <a href="https://restcountries.com/" target="_blank">restcountries.com</a>
          </ion-item>
        </ion-item-group>
        <ion-item-group>
          <ion-item-divider><ion-label>Data providers</ion-label></ion-item-divider>
          <ion-item>
            <ion-label class="ion-text-wrap">The Story Shack</ion-label>
            <a href="https://thestoryshack.com/" target="_blank">thestoryshack.com</a>
          </ion-item>
        </ion-item-group>
        <ion-item-group>
          <ion-item-divider><ion-label>Developers</ion-label></ion-item-divider>
          <ion-item>
            <ion-label>siggi</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>mj</ion-label>
          </ion-item>
        </ion-item-group>

        <div class="ion-text-center">
          <ion-icon name="heart" color="danger"></ion-icon>
        </div>
      </ion-card-content>
    </ion-card>
  </section>
</ion-content>
