import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from 'projects/services/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<String> = new BehaviorSubject<String>(null);

  constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authRequest = request;
    if (this.authService.isAuthenticated()) {
      authRequest = this.authenticate(request, this.authService.getCurrentUser().token);
    }

    return next.handle(authRequest).pipe(
      catchError((error) => {
        // access token expired
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !authRequest.url.includes('api/tokens')
        ) {
          return this.refreshAuthentication(authRequest, next);
        }

        return throwError(() => error);
      })
    );
  }

  private authenticate(request: HttpRequest<any>, token: String): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });
  }

  private refreshAuthentication(request: HttpRequest<any>, next: HttpHandler) {
    // get a new one
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      this.authService.refresh().then(() => {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(this.authService.getCurrentUser().token);

        return next.handle(this.authenticate(request, this.authService.getCurrentUser().token));
      }).catch(() => {
        this.refreshTokenInProgress = false;
        this.authService.logout();
      });
    }

    // wait for valid token
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => {
        return next.handle(this.authenticate(request, token));
      }),
      catchError((error) => {
        this.refreshTokenInProgress = false;
        this.authService.logout();

        return throwError(() => error);
      })
    );
  }
}
