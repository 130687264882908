import { Component, ElementRef, ViewChild } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-joker-effect',
  templateUrl: './joker-effect.component.html',
  styleUrls: ['./joker-effect.component.scss'],
})
export class JokerEffectComponent {
  @ViewChild('modal', { read: ElementRef }) modal: ElementRef;
  @ViewChild('canvas', { read: ElementRef }) canvas: ElementRef;

  queue: Array<any> = new Array();

  constructor(private animationController: AnimationController) {}

  show(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.queue.length == 0) {
        this.fadeIn();
      }

      const anime = this.createAnimation(this.canvas.nativeElement);
      anime.play();

      setTimeout(() => {
        if (this.queue.length > 1) {
          this.queue.pop();
          resolve();
        } else {
          this.fadeOut().then(() => {
            resolve();
          });
        }
      }, 800);
    });
  }

  private fadeIn(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.modal.nativeElement.style.display = 'flex';
      this.animationController
        .create('fade-in')
        .addElement(this.modal.nativeElement)
        .duration(200)
        .keyframes([
          { offset: 0, opacity: 0 },
          { offset: 1, opacity: 1 },
        ])
        .onFinish(() => {
          resolve();
        })
        .play();
    });
  }

  private fadeOut(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.animationController
        .create('fade-out')
        .addElement(this.modal.nativeElement)
        .duration(200)
        .keyframes([
          { offset: 0, opacity: 1 },
          { offset: 1, opacity: 0 },
        ])
        .onFinish(() => {
          this.modal.nativeElement.style.display = 'none';
          resolve();
        })
        .play();
    });
  }

  private createAnimation(element: HTMLElement): any {
    const animation = this.animationController
      .create('joker-effect')
      .addElement(element)
      .duration(1000)
      .keyframes([
        { offset: 0, transform: 'scale(0)' },
        { offset: 0.1, transform: 'scale(1.2)' },
        { offset: 0.3, transform: 'scale(1)' },
        { offset: 0.9, transform: 'scale(1)' },
        { offset: 1, transform: 'scale(0.2)' },
      ]);
    return animation;
  }
}
