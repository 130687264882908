export class UserGame {
  id: string;
  uid: string;
  gid: string;
  data: {
    won: boolean;
    game: string;
    score: number;
    played: Date;
  };

  constructor() {
    this.data = { won: null, game: null, score: null, played: null };
  }
}
