<ion-toolbar transparent>
  <ion-buttons slot="start">
    <ion-button (click)="exitGame()">
      <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>

  <div class="header-content">
    <app-player-icon [player]="player"></app-player-icon>
    <app-player-score [score]="player.score"></app-player-score>
    <app-joker-icon [jokers]="player.jokers"></app-joker-icon>
  </div>

  <ion-buttons slot="end">
    <ion-button (click)="toggleMenu()">
      <ion-icon slot="icon-only" name="people-circle-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-menu side="end" menuId="players" contentId="main">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button (click)="toggleMenu()">
        <ion-icon slot="icon-only" name="people-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-content>
    <div class="menu-content">
      <div class="player-card-wrapper">
        <app-player-card
          *ngFor="let player of players"
          [mode]="'mini'"
          [player]="player"
        ></app-player-card>
      </div>

      <div class="spectators-wrapper">
        <ion-label>
          {{ spectators.length }}
        </ion-label>
        <ion-icon name="eye-outline"></ion-icon>
      </div>
    </div>
  </ion-content>
</ion-menu>
