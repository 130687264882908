import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Settings } from 'projects/models/src/lib/api/settings';
import { GameConfig } from 'projects/models/src/public-api';
import { Mode } from 'projects/models/src/lib/game-mode.model';
import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class GameSettingsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  private log: Logger;

  private readonly _settings = new BehaviorSubject<Settings>(new Settings());

  constructor(private httpClient: HttpClient, private logService: LogService) {
    this.log = this.logService.forComponent('service:game-settings');

    // create initial Settings
    this._settings.next(this.createSettings());
  }

  load(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(environment.SERVER_URL + 'api/settings', this.httpOptions).subscribe(
        (data: Settings) => {
          // update Settings
          this._settings.next(data);
          resolve(true);
        },
        (error) => {
          this.log.debug('Error loading settings from backend', error);
          // keep app running
          resolve(true);
        }
      );
    });
  }

  getSettings(): Settings {
    return this._settings.value;
  }

  getSettingsObservable(): Observable<Settings> {
    return this._settings.asObservable();
  }

  private createSettings(): Settings {
    const settings = new Settings();

    const gameConfig = new GameConfig();
    gameConfig.mode = 'random_all';
    gameConfig.rounds = 5;
    gameConfig.roundtime = 15 * 1000;
    gameConfig.jokers = true;
    settings.gameConfig = gameConfig;

    const mode = new Mode();
    mode.id = 'random_all';
    mode.name = 'random';
    mode.color = 'black';
    mode.submodes = [];
    settings.gameModes = [mode];

    settings.randomName = '';

    return settings;
  }
}
