import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Token, User } from 'projects/models/src/public-api';
import { GameSettingsService } from './game-settings.service';
import { Logger, LogService } from './log.service';
import { TokenApiService } from './token-api.service';
import { UserApiService } from './user-api.service';

// refresh token
const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private unsubscribe = new Subject<void>();

  private _authenticationState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private log: Logger;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private gameSettingsService: GameSettingsService,
    private tokenApiService: TokenApiService,
    private userApiService: UserApiService,
    private logService: LogService
  ) {
    this.log = this.logService.forComponent('service:auth');

    this.gameSettingsService
      .getSettingsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((settings) => {
        if (!this._authenticationState.value && environment.features.coolplayernames) {
          this.log.debug('setting random playername');

          const newUser = new User();
          newUser.username = settings.randomName;
          this._authenticationState.next(false);
          this._currentUserSubject.next(newUser);
        }
      });

    this.platform.ready().then(() => {
      this.storage.create().then(() => {
        // ignore failure
        this.refresh().catch((_) => {});
      });
    });
  }

  isAuthenticated(): boolean {
    return this._authenticationState.value;
  }

  getAuthenticatedObservable(): Observable<boolean> {
    return this._authenticationState.asObservable();
  }

  getCurrentUser(): User {
    return this._currentUserSubject.value;
  }

  getCurrentUserObservable(): Observable<User> {
    return this._currentUserSubject.asObservable();
  }

  login(user: User): Promise<User> {
    this.log.debug('logging in user ' + user?.username);
    return new Promise((resolve, reject) => {
      this.tokenApiService.create(user.username, user.password).subscribe({
        next: (token: Token) => {
          this.storage.set(TOKEN_KEY, token.refresh_token);

          const newUser = this.decodeToken(token.access_token);
          this._currentUserSubject.next(newUser);
          this._authenticationState.next(true);

          this.log.debug('logged in as ' + newUser.username + ' (' + newUser.id + ')');

          resolve(newUser);
        },
        error: (_: HttpErrorResponse) => {
          reject('Login Failed &#128558;');
        },
      });
    });
  }

  refresh(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.storage.get(TOKEN_KEY).then((token) => {
        if (token) {
          this.log.debug('refreshing login');
          this.tokenApiService.refresh(token).subscribe({
            next: (token: Token) => {
              const newUser = this.decodeToken(token.access_token);
              this.log.debug('logged in as ' + newUser.username + ' (' + newUser.id + ')');

              this._currentUserSubject.next(newUser);
              this._authenticationState.next(true);

              resolve();
            },
            error: (error) => {
              this.log.debug('refresh failed', error);
              reject();
            },
          });
        } else {
          reject();
        }
      });
    });
  }

  register(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userApiService.register(user).subscribe({
        next: (_: User) => {
          resolve();
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 409) {
            reject('The ' + error.error.message + ' already exists &#128558;');
          } else {
            reject('Registration Failed &#128558;');
          }
        },
      });
    });
  }

  reset(id: String, reset: String, password: String): Promise<void> {
    return new Promise((resolve) => {
      this.userApiService.reset(id, reset, password).subscribe({
        next: (_: User) => {
          resolve();
        },
      });
    });
  }

  verify(email: String, verification: String): Promise<boolean> {
    return new Promise((resolve) => {
      this.userApiService.verify(email, verification).subscribe(
        (user: User) => {
          //login after successful registration
          this.login(user)
            .then(() => {
              resolve(true);
              this.refresh();
            })
            .catch(() => {
              resolve(false);
            });
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  forgot(account: String): Promise<boolean> {
    return new Promise((resolve) => {
      this.userApiService.forgot(account).subscribe(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  update(user: User): Promise<boolean> {
    return new Promise((resolve) => {
      this.userApiService.update(user).subscribe(
        (_: User) => {
          resolve(true);
          this.refresh();
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  postPicture(user: User, file: File): Promise<boolean> {
    return new Promise((resolve) => {
      this.userApiService.postPicture(user, file).subscribe(
        (_: any) => {
          resolve(true);
          this.refresh();
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  deletePicture(user: User): Promise<boolean> {
    return new Promise((resolve) => {
      const updateUser: User = new User();
      updateUser.id = user.id;
      updateUser.data.handle = undefined;
      updateUser.data.picture = null;
      this.userApiService.update(updateUser).subscribe(
        (_: User) => {
          resolve(true);
          this.refresh();
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  logout(): void {
    this.storage.remove(TOKEN_KEY).then(() => {
      this._authenticationState.next(false);
    });
  }

  private decodeToken(token: string): User {
    const decoded = jwt_decode(token) as any;

    const user = new User();
    user.token = token;
    user.id = decoded.id;
    user.username = decoded.username;
    user.email = decoded.email;
    user.data = decoded.data;

    return user;
  }
}
