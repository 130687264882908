import { Joker } from './joker.model';
import { Clientplatform } from './clientplatform.model';

export class Player {
  id: number;
  number: number;
  host: boolean;
  name: string;
  joinedTime: Date;
  score: number;
  idle: boolean;
  ready: boolean;
  platform: Clientplatform;
  userId?: string;
  picture?: string;
  gameId?: string;
  jokers: Array<Joker>;
}
