import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PreloadSelectedModules } from './app.preloading';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'game-box',
    loadChildren: () => import('./game-box/game-box.module').then((m) => m.GameBoxPageModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'scoreboard',
    loadChildren: () =>
      import('./scoreboard/scoreboard.module').then((m) => m.ScoreboardPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset/reset.module').then((m) => m.ResetPageModule),
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then((m) => m.UpdatePageModule),
  },
  {
    path: 'offline',
    loadChildren: () => import('./offline/offline.module').then((m) => m.OfflinePageModule),
  },
  {
    path: 'verify',
    loadChildren: () => import('./verify/verify.module').then((m) => m.VerifyPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadSelectedModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
