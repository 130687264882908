import { Answer } from './answer.model';

export class Question {
  category: string;
  subcategory?: string;
  type: string;
  difficulty: string;
  text: string;
  points: number;
  answers: Array<Answer>;

  year?: string;
  images?: Array<string>;

  song?: string;
  popularity?: string;
}
