<ion-grid
  *ngIf="mode === 'limited'"
  [ngClass]="'color' + player.number"
  [class.ready]="!player.host && player.ready"
  class="playercard"
>
  <ion-row class="ion-justify-content-between ion-align-items-center">
    <ion-col size="3">
      <ion-avatar
        [ngClass]="'color' + player.number"
        [class.currentplayer]="gameStateService.isCurrentPlayer(player)"
        (click)="presentEmojiPopover($event)"
      >
        <img *ngIf="!player.picture" src="/assets/doge.vector.svg" alt="{ player.name }" />
        <img *ngIf="player.picture" [src]="player.picture" alt="{ player.name }" />
        <span *ngIf="player.host">HOST</span>
      </ion-avatar>
    </ion-col>
    <ion-col class="ion-text-center">
      <ion-label>{{ player.name }}</ion-label>
    </ion-col>
    <ion-col size="2">
      <ion-icon
        *ngIf="!player.host && player.ready"
        class="player-ready-icon"
        name="checkmark-circle"
        color="success"
      ></ion-icon>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid
  *ngIf="mode === 'score'"
  [ngClass]="'color' + player.number"
  [class.ready]="!player.host && player.ready"
  class="playercard"
>
  <ion-row class="ion-justify-content-between ion-align-items-center">
    <ion-col size="3">
      <ion-avatar [ngClass]="'color' + player.number">
        <img *ngIf="!player.picture" src="/assets/doge.vector.svg" alt="{ player.name }" />
        <img *ngIf="player.picture" [src]="player.picture" alt="{ player.name }" />
        <span *ngIf="player.host">HOST</span>
      </ion-avatar>
    </ion-col>
    <ion-col class="ion-text-center">
      <ion-label>{{ player.name }}</ion-label>
    </ion-col>
    <ion-col class="ion-text-center">
      <ion-label>{{ player.score }}</ion-label>
    </ion-col>
    <ion-col size="2">
      <ion-icon
        *ngIf="!player.host && player.ready"
        class="player-ready-icon"
        name="checkmark-circle"
        color="success"
      ></ion-icon>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="mode === 'mini'" [ngClass]="'color' + player.number" class="playercard card-mini">
  <ion-row>
    <ion-col>
      <ion-avatar [ngClass]="'color' + player.number" class="center">
        <img *ngIf="!player.picture" src="/assets/doge.vector.svg" alt="{ player.name }" />
        <img *ngIf="player.picture" [src]="player.picture" alt="{ player.name }" />
        <span *ngIf="player.host">HOST</span>
      </ion-avatar>
      <ion-row class="ion-text-center">
        <ion-col>
          <ion-label>{{ player.name }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-text-center">
        <ion-col>
          <ion-label>{{ player.score }}</ion-label>
          <ion-label
            *ngIf="gameStateService.getGame().running && getPlayerScore() && isPositiveScore()"
            color="success"
          >
            +{{ getPlayerScore() }}
          </ion-label>
          <ion-label
            *ngIf="gameStateService.getGame().running && getPlayerScore() && !isPositiveScore()"
            class="text"
            color="danger"
          >
            {{ getPlayerScore() }}
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
