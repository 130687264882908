import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';

import { User } from 'projects/models/src/public-api';
import { MessageService, AuthService, FormService } from 'projects/services/src/public-api';
import { ModalService } from '../services/modal.service';
import { LoginPage } from '../login/login.page';
import { VerifyPage } from '../verify/verify.page';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage {
  registerForm: UntypedFormGroup;
  isSubmitted: boolean = false;
  success: boolean = false;

  constructor(
    public toastController: ToastController,
    public modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private formService: FormService,
    private messageService: MessageService,
    private modalService: ModalService
  ) {
    this.registerForm = this.formBuilder.group(
      {
        username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern('.{6,}')]],
        confirmPassword: ['', []],
      },
      {
        validator: this.formService.passwordsMatch,
      }
    );
  }

  register() {
    this.isSubmitted = true;
    if (this.registerForm.valid) {
      const user: User = new User();
      user.username = this.registerForm.value.username;
      user.email = this.registerForm.value.email;
      user.password = this.registerForm.value.password;

      this.authService
        .register(user)
        .then(() => {
          this.success = true;

          setTimeout(() => {
            this.dismissModal();
          }, 5000);
        })
        .catch((error) => {
          this.messageService.presentToast(error);
        });
    } else {
      return false;
    }
  }

  async dismissModal() {
    this.modalService.dismissModal();
  }

  go2Login(): void {
    this.modalService.presentModal(LoginPage);
  }

  go2Verify(): void {
    this.modalService.presentModal(VerifyPage);
  }
}
