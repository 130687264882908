<div
  (click)="presentEmojiPopover($event)"
  [ngClass]="'color' + player.number"
  class="player"
  #playerElement
>
  <img *ngIf="!player.picture" src="/assets/doge.vector.svg" alt="{ player.name }" />
  <img *ngIf="player.picture" [src]="player.picture" alt="{ player.name }" />
  <span *ngIf="player.host">HOST</span>
</div>
