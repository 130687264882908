import { Injectable } from '@angular/core';
import { debug } from 'debug';

@Injectable({
  providedIn: 'root',
})
class LogService {
  prefix: string = 'qes';

  enable(filter: string): void {
    debug.enable(filter);
  }

  enableAll(): void {
    debug.enable('*');
  }

  disable(): void {
    debug.disable();
  }

  forComponent(component: string): Logger {
    return new Logger(debug(this.prefix + ':' + component));
  }
}

class Logger {
  private impl: debug.Debugger;

  constructor(impl: debug.Debugger) {
    this.impl = impl;
  }

  debug(message: string, addition?: any): void {
    if (addition) {
      this.impl(message, addition);
    } else {
      this.impl(message);
    }
  }
}

export { LogService, Logger };
