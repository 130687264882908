<main>
  <section *ngIf="success" class="success-section">
    <div>
      <h1>Well done 🤗</h1>
      <div>Now go Enjoy..</div>
    </div>
  </section>

  <section *ngIf="failure" class="failure-section">
    <div>
      <h1>Hang on 😓</h1>
      <div>Something went wrong..</div>
    </div>
  </section>
</main>
