<section>
  <div class="category-title">Game Settings</div>
  <ion-card>
    <ion-card-title>
      <ion-item lines="none">
        <div class="sub-category-title">Configuration</div>
        <ion-icon
          *ngIf="customizeGame && gameStateService.getCurrentPlayer().host"
          (click)="toggleCustomizeGame()"
          name="close-outline"
          size="medium"
          slot="end"
        ></ion-icon>
        <ion-icon
          *ngIf="!customizeGame && gameStateService.getCurrentPlayer().host"
          (click)="toggleCustomizeGame()"
          name="settings-outline"
          size="medium"
          slot="end"
        ></ion-icon>
      </ion-item>
    </ion-card-title>

    <ion-card-content class="setting-content">
      <ion-badge class="setting-game-id" color="primary">
        <div>
          <span class="setting-item-title">Game</span>
          <br />
          <span>{{ gameStateService.getGame().id }}</span>
        </div>
        <qrcode
          (click)="openQrcodeModal()"
          [qrdata]="'0000'"
          [width]="30"
          [errorCorrectionLevel]="'L'"
          [elementType]="'svg'"
          break
        ></qrcode>
      </ion-badge>
      <ion-badge [ngStyle]="{ 'background-color': modeColor }" class="setting-mode">
        <span class="setting-item-title">Mode</span>
        <br />
        <span>{{ gameStateService.getGame().config.mode | titlecase }}</span>
      </ion-badge>
      <ion-badge color="medium">
        <span class="setting-item-title">Rounds</span>
        <br />
        <span>{{ gameStateService.getGame().config.rounds }}</span>
      </ion-badge>
      <ion-badge color="medium">
        <span class="setting-item-title">Max Player</span>
        <br />
        <span>{{ gameStateService.getGame().config.players }}</span>
      </ion-badge>
      <ion-badge color="medium">
        <span class="setting-item-title">Round Time</span>
        <br />
        <span>{{ gameStateService.getGame().config.roundtime / 1000 }}s</span>
      </ion-badge>
    </ion-card-content>

    <ion-progress-bar buffer="time" value="{{ time }}"></ion-progress-bar>

    <ion-card-content
      *ngIf="customizeGame && gameStateService.getCurrentPlayer().host"
      class="configuration-content"
    >
      <form (ngSubmit)="changeGameSettings()" [formGroup]="hostForm" novalidate>
        <ion-list>
          <ion-item>
            <ion-input
              formControlName="playerName"
              label="Playername"
              labelPlacement="floating"
              type="text"
            ></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="
              !hostForm.controls.playerName.valid &&
              (hostForm.controls.playerName.dirty || isSubmitted)
            "
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter your playername
          </div>

          <ion-item class="mode">
            <ion-select
              (ionChange)="onChangeMode($event)"
              value="{{ hostForm.controls.mode.value }}"
              placeholder="Select"
              formControlName="mode"
              label="Mode"
              labelPlacement="floating"
            >
              <ion-select-option *ngFor="let mode of allModes" value="{{ mode.name }}">
                {{ mode.name | titlecase }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="mode">
            <ion-select
              value="{{ hostForm.controls.submode.value }}"
              placeholder="Select"
              formControlName="submode"
              label="Submode"
              labelPlacement="floating"
            >
              <ion-select-option *ngFor="let subMode of subModes" value="{{ subMode }}">
                {{ subMode | titlecase }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-toggle color="primary" formControlName="jokers">Enable Joker</ion-toggle>
          </ion-item>

          <ion-item>
            <ion-range min="5" max="30" color="secondary" formControlName="rounds">
              <div slot="label">Rounds: <ion-text> {{ hostForm.controls.rounds.value }}</ion-text></div>
              <ion-label slot="start">5</ion-label>
              <ion-label slot="end">30</ion-label>
            </ion-range>
          </ion-item>

          <ion-item>
            <ion-range min="5" max="30" color="secondary" formControlName="roundtime">
              <div slot="label">Time: <ion-text> {{ hostForm.controls.roundtime.value }}s</ion-text></div>
              <ion-label slot="start">5s</ion-label>
              <ion-label slot="end">30s</ion-label>
            </ion-range>
          </ion-item>
        </ion-list>

        <button class="button-style" type="submit" expand="block">
          <span class="inner">
            <ion-icon slot="start" name="save-outline"></ion-icon>
            <span>Save Custom Game</span>
          </span>
        </button>
      </form>
    </ion-card-content>

    <ion-card-content *ngIf="!customizeGame">
      <div class="game-buttons">
        <button
          *ngIf="gameStateService.getCurrentPlayer().host"
          (click)="start()"
          class="button-style"
          id="start"
        >
          <span class="inner">
            <ion-icon slot="start" name="play-outline"></ion-icon>
            <span>Start</span>
          </span>
        </button>

        <button
          *ngIf="!gameStateService.getCurrentPlayer().host"
          (click)="ready(!gameStateService.getCurrentPlayer().ready)"
          class="button-style"
          expand="block"
          id="ready"
        >
          <span *ngIf="!gameStateService.getCurrentPlayer().ready" class="inner">
            <ion-icon slot="start" name="checkmark-circle"></ion-icon>
            <span>Ready</span>
          </span>

          <span *ngIf="gameStateService.getCurrentPlayer().ready" class="inner">
            <ion-icon slot="start" name="arrow-back-circle"></ion-icon>
            <span>Unready</span>
          </span>
        </button>

        <button (click)="openShareModal()" class="button-style" id="share">
          <span class="inner">
            <ion-icon slot="start" name="share-social-outline"></ion-icon>
          </span>
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</section>

<div *ngIf="!customizeGame">
  <section>
    <div class="category-title">Lobby</div>
    <div class="lobby-content">
      <app-player-card
        *ngFor="let player of gameStateService.getGame().players"
        [mode]="'limited'"
        [player]="player"
      ></app-player-card>
    </div>
  </section>
</div>

<div (click)="closeQrcodeModal()" id="qrcode-modal" #qrcode>
  <div></div>
  <qrcode
    [qrdata]="joinUrl"
    [width]="256"
    [errorCorrectionLevel]="'M'"
    [elementType]="'svg'"
    break
  ></qrcode>
</div>
