import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Token } from 'projects/models/src/public-api';
import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class TokenApiService {
  private log: Logger;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient, private logService: LogService) {
    this.log = this.logService.forComponent('service:token-api');
  }

  create(username: String, password: String): Observable<Token> {
    return this.httpClient.post<Token>(
      environment.SERVER_URL + 'api/tokens',
      { username: username, password: password },
      this.httpOptions
    );
  }

  refresh(refreshToken: String): Observable<Token> {
    return this.httpClient.post<Token>(
      environment.SERVER_URL + 'api/tokens/refresh',
      { refresh_token: refreshToken },
      this.httpOptions
    );
  }
}
