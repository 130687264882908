import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GameService } from 'projects/services/src/public-api';

@Component({
  selector: 'app-emoji-popover',
  templateUrl: './emoji-popover.component.html',
  styleUrls: ['./emoji-popover.component.scss'],
})
export class EmojiPopoverComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();

  constructor(private popoverController: PopoverController, private gameService: GameService) {}

  ngOnInit(): void {
    // STARTED GAME
    this.gameService
      .startedGame()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.dismissPopover();
      });

    // BEGIN ROUND
    this.gameService
      .beginRound()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.dismissPopover();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  drawEmoji(emoji: number): void {
    this.gameService.emoji(emoji);
    this.dismissPopover();
  }

  codePointToString(codePoint: number): string {
    return String.fromCodePoint(codePoint);
  }

  async dismissPopover(): Promise<void> {
    await this.popoverController.dismiss();
  }
}
