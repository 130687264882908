import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Joker } from 'projects/models/src/lib/joker.model';
import { GameService, MessageService } from 'projects/services/src/public-api';
import { JokerEffectService } from 'projects/components/src/lib/joker-effect/joker-effect.service';

@Component({
  selector: 'app-joker-popover',
  templateUrl: './joker-popover.component.html',
  styleUrls: ['./joker-popover.component.scss'],
})
export class JokerPopoverComponent implements OnInit {
  unsubscribe = new Subject<void>();

  jokers: Array<Joker>;

  constructor(
    public navParams: NavParams,
    private popoverController: PopoverController,
    private gameService: GameService,
    private messageService: MessageService,
    private jokerEffectService: JokerEffectService
  ) {
    this.jokers = this.navParams.get('jokers');
  }

  ngOnInit(): void {
    // END ROUND
    this.gameService
      .endRound()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((game) => {
        this.dismissPopover();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChange(): void {
    this.jokers = this.navParams.get('jokers');
  }

  useJoker(joker: Joker): void {
    this.gameService
      .joker(joker)
      .then(() => {
        this.jokerEffectService.show(joker);
      })
      .catch(() => {
        this.messageService.presentJokerToast('Joker ' + joker.name + ' is not available.');
      });
    this.dismissPopover();
  }

  async dismissPopover(): Promise<void> {
    await this.popoverController.dismiss();
  }
}
