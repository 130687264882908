<div [ngClass]="{ signed: sign }" class="score">
  <div class="sign">
    <span *ngIf="sign">{{ sign }}</span>
  </div>

  <div class="digits">
    <div class="digit-wrapper" #digit1000>
      <span *ngFor="let i of numbers" class="digit">{{ i }}</span>
    </div>
    <div class="digit-wrapper" #digit100>
      <span *ngFor="let i of numbers" class="digit">{{ i }}</span>
    </div>
    <div class="digit-wrapper" #digit10>
      <span *ngFor="let i of numbers" class="digit">{{ i }}</span>
    </div>
    <div class="digit-wrapper" #digit1>
      <span *ngFor="let i of numbers" class="digit">{{ i }}</span>
    </div>
  </div>
</div>
