import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { GameConfig, Question, Round } from 'projects/models/src/public-api';

@Component({
  selector: 'app-question-box',
  templateUrl: './question-box.component.html',
  styleUrls: ['./question-box.component.scss'],
})
export class QuestionBoxComponent {
  @Input() gameConfig: GameConfig;
  @Input() round: Round;
  @Input() audioPlaying: boolean;
  @Input() time: number;
  @Output() audioControl = new EventEmitter<boolean>();

  @ViewChild('question', { static: false }) questionElement: ElementRef;
  @ViewChild('audiomuted', { static: false, read: ElementRef }) audioElement: ElementRef;
  @ViewChild('slides', { static: false }) slidesElement: ElementRef;

  // Images Slider
  slideOptsImages = {
    speed: 100,
    slidesPerView: 1,
    centeredSlides: true,
    updateOnImagesReady: false,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  };

  categoryColor: string;
  questionClass: string;
  questionFontSize: string;

  constructor(private animationController: AnimationController) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.questionElement && changes.round?.currentValue?.question) {
      this.updateRoundState(
        changes.round.currentValue as Round,
        changes.round.previousValue as Round
      );
    } else if (this.audioPlaying !== undefined) {
      // autoplay error
      if (this.round?.question?.song && this.audioPlaying === null) {
        this.animateVolumeNotify();
      }
    }
  }

  toggleAudio(): void {
    this.setAudioPlaying(!this.audioPlaying);
  }

  private updateRoundState(currentRound: Round, previousValue?: Round): void {
    this.questionClass = this.getQuestionClass(currentRound.question);
    this.questionFontSize = this.getQuestionFontSize(currentRound.question);

    // new round
    if (!previousValue || previousValue.id != currentRound.id) {
      // reset audio
      //if (currentRound.question.song) {
      //  this.audioPlaying = true;
      //}

      if (currentRound.question.images) {
        // scroll to question
        this.questionElement.nativeElement.scrollIntoView();

        // scroll to slides
        setTimeout(() => {
          this.slidesElement?.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }, 1500);
      }
    }
  }

  private setAudioPlaying(audioPlaying: boolean): void {
    this.audioPlaying = audioPlaying;
    this.audioControl.next(audioPlaying);
  }

  private animateVolumeNotify(): void {
    const animation = this.animationController
      .create('volume-notify')
      .addElement(this.audioElement.nativeElement)
      .duration(400)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform: 'scale(0.9)' },
        { offset: 0.7, transform: 'scale(1.2)' },
        { offset: 1, transform: 'scale(1)' },
      ]);
    animation.play();
  }

  private getQuestionClass(question: Question): string {
    if (this.isGamingQuestion(question)) {
      return 'questionGaming';
    } else if (this.isMovieQuestion(question)) {
      return 'questionMovie';
    } else if (this.isMusicQuestion(question)) {
      return 'questionMusic';
    } else if (this.isSportsQuestion(question)) {
      return 'questionSports';
    } else if (this.isSoccerQuestion(question)) {
      return 'questionSoccer';
    }
  }

  private isMovieQuestion(question: Question): boolean {
    if (
      this.isQuestionCategory(question, 'movie') ||
      this.isQuestionCategory(question, 'film and tv') ||
      (this.isQuestionCategory(question, 'entertainment') &&
        this.isQuestionSubCategory(question, 'film'))
    ) {
      return true;
    }
    return false;
  }

  private isGamingQuestion(question: Question): boolean {
    if (
      this.isQuestionCategory(question, 'gaming') ||
      (this.isQuestionCategory(question, 'entertainment') &&
        this.isQuestionSubCategory(question, 'video games'))
    ) {
      return true;
    }
    return false;
  }

  private isMusicQuestion(question: Question): boolean {
    if (
      this.isQuestionCategory(question, 'music') ||
      (this.isQuestionCategory(question, 'entertainment') &&
        this.isQuestionSubCategory(question, 'music'))
    ) {
      return true;
    }
    return false;
  }

  private isSportsQuestion(question: Question): boolean {
    if (
      this.isQuestionCategory(question, 'sports') ||
      this.isQuestionCategory(question, 'sport and leisure')
    ) {
      return true;
    }
    return false;
  }

  private isSoccerQuestion(question: Question): boolean {
    if (this.isQuestionCategory(question, 'soccer')) {
      return true;
    }
    return false;
  }

  private isQuestionCategory(question: Question, category: string): boolean {
    return question?.category?.toLowerCase().includes(category);
  }

  private isQuestionSubCategory(question: Question, subcategory: string): boolean {
    return question?.subcategory?.toLowerCase().includes(subcategory);
  }

  private getQuestionFontSize(question: Question): string {
    if (question?.text.length > 80) {
      return '3vh';
    } else if (question?.text.length > 60) {
      return '3.5vh';
    }
    return '4vh';
  }
}
