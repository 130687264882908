import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Answer, Jokered, Round } from 'projects/models/src/public-api';
import { ErrorApiService, GameService, GameStateService } from 'projects/services/src/public-api';

@Component({
  selector: 'app-game-screen',
  templateUrl: './game-screen.component.html',
  styleUrls: ['./game-screen.component.scss'],
})
export class GameScreenComponent implements OnInit, OnDestroy {
  roundTimer: number = 1;
  countdownInterval: any;

  // MusicPlayer
  audio: HTMLAudioElement = new Audio();
  // true, false, null (play error)
  audioPlaying = false;

  unsubscribe = new Subject<void>();

  constructor(
    public gameStateService: GameStateService,
    private errorApiService: ErrorApiService,
    private gameService: GameService
  ) {}

  ngOnInit(): void {
    this.roundTimer = this.gameStateService.getGame().config.roundtime / 1000;

    // BEGIN
    this.gameService
      .beginRound()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((begin) => {
        this.onBeginRound(begin.game.round);
      });

    // END
    this.gameService
      .endRound()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((end) => {
        this.onEndRound(end.game.round);
      });

    // JOKERED
    this.gameService
      .jokeredInGame()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((jokered) => {
        this.onJokered(jokered);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stopAudio();
    this.resetRoundTimer();
  }

  private onBeginRound(round: Round): void {
    this.startRoundTimer(round.beginTime);

    // Music Question
    if (round.question?.song) {
      this.setAudioSrc(round.question.song);
      this.playAudio();
    }
  }

  private onEndRound(round: Round): void {
    this.resetRoundTimer();
    this.stopAudio();
  }

  private onJokered(jokered: Jokered): void {
    // Timestretch
    if (jokered.joker.id == 2) {
      this.startRoundTimer(null);
    }
  }

  // TIMER
  private startRoundTimer(beginTime: string): void {
    this.resetRoundTimer();

    this.roundTimer = this.gameStateService.getGame().config.roundtime / 1000;

    // adjust timer based on time left in round
    //TODO: calculate remaining time on server. clock difference is too big..
    if (beginTime) {
      const millisLeft = new Date().getTime() - Date.parse(beginTime);
      const secondsLeft = Math.floor(millisLeft / 1000);
      if (secondsLeft > 0) {
        this.roundTimer = this.roundTimer - secondsLeft;
      }
    }

    this.countdownInterval = setInterval(() => {
      this.roundTimer--;
      if (this.roundTimer < 0) {
        this.resetRoundTimer();
      }
    }, 1000);
  }

  private resetRoundTimer(): void {
    this.roundTimer = 0;
    clearInterval(this.countdownInterval);
  }

  // MUSIC PLAYER
  toggleAudio(event: any): void {
    if (event == true) {
      this.playAudio();
    } else {
      this.stopAudio();
    }
  }

  private playAudio(): void {
    this.audio.load();
    this.audio.currentTime = 15;
    this.audio
      .play()
      .then(() => {
        this.audioPlaying = true;
      })
      .catch((error) => {
        this.audioPlaying = null;
        this.errorApiService.send(error);
      });
  }

  private stopAudio(): void {
    this.audio.pause();
    this.audioPlaying = false;
  }

  private setAudioSrc(src: string): void {
    this.audio.src = src;
  }

  answer(event: Answer): void {
    this.gameService.answer(event);
  }
}
