<ion-content>
  <header>
    <ion-toolbar>
      <ion-title>
        <ion-icon name="person-add-outline"></ion-icon>
        <ion-label class="category-title">Register</ion-label>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </header>

  <section>
    <ion-card>
      <ion-card-content *ngIf="!success" class="register-content">
        <form [formGroup]="registerForm" (ngSubmit)="register()" novalidate>
          <ion-item lines="full">
            <ion-input formControlName="username" label="Username" labelPlacement="floating" type="text"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!registerForm.controls.username.valid && (registerForm.controls.username.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter letters and numbers
          </div>

          <ion-item lines="full">
            <ion-input formControlName="email" label="E-mail" labelPlacement="floating" type="email"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!registerForm.controls.email.valid && (registerForm.controls.email.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter your E-mail address
          </div>

          <ion-item lines="full">
            <ion-input formControlName="password" label="Password" labelPlacement="floating" type="password"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!registerForm.controls.password.valid && (registerForm.controls.password.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Password must be longer than 6 characters
          </div>

          <ion-item lines="full">
            <ion-input formControlName="confirmPassword" label="Confirm Password" labelPlacement="floating" type="password"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="registerForm.errors?.mismatch && (registerForm.controls.confirmPassword.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Passwords must match
          </div>

          <button class="button-style ion-activatable" type="submit" expand="block">
            <span class="inner">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              <span>Register</span>
            </span>
            <ion-ripple-effect></ion-ripple-effect>
          </button>
        </form>

        <ion-item class="item--login">
          <ion-label class="ion-text-center">
            Member?
            <a (click)="go2Login()">Login</a>
          </ion-label>
        </ion-item>
      </ion-card-content>

      <ion-card-content *ngIf="success" class="complete-content">
          <h1>Congratulations 🎉</h1>
          <div>You are one step away..</div>
          <div>Check your E-Mails and follow the link.</div>
      </ion-card-content>
    </ion-card>
  </section>
</ion-content>
