import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { User } from 'projects/models/src/public-api';
import { MessageService, AuthService } from 'projects/services/src/public-api';
import { ForgotPage } from '../forgot/forgot.page';
import { RegisterPage } from '../register/register.page';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  loginForm: UntypedFormGroup;
  isSubmitted: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  login() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      const user: User = new User();
      user.username = this.loginForm.value.username;
      user.password = this.loginForm.value.password;

      this.authService
        .login(user)
        .then((user) => {
          this.messageService.presentToast('Welcome back, ' + user.data.handle + ' &#128515;');
          this.dismissModal();
        })
        .catch((error) => {
          this.loginForm.controls.password.setValue('');
          this.messageService.presentToast(error);
        });
    } else {
      return false;
    }
  }

  async dismissModal(): Promise<boolean> {
    return this.modalService.dismissModal();
  }

  go2Register(): void {
    this.modalService.presentModal(RegisterPage);
  }

  go2Forgot(): void {
    this.modalService.presentModal(ForgotPage);
  }
}
