import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  passwordsMatch(form: UntypedFormGroup): { [error: string]: any } {
    let a = form.get('password');
    let b = form.get('confirmPassword');
    let result: { [error: string]: any } = {};
    if ((a.touched || b.touched) && a.value !== b.value) {
      result['mismatch'] = true;
    }
    return result;
  }
}
