import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

import { environment } from 'src/environments/environment';
import { GameSettingsService } from './game-settings.service';
import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class GameSocketService {
  private log: Logger;

  private socket: Socket;

  private readonly connected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly reconnecting: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private gameSettingsService: GameSettingsService, private logService: LogService) {
    this.log = this.logService.forComponent('service:game-socket');

    this.socket = this.createSocket();
    this.bindSocket();
  }

  getSocket(): Socket {
    return this.socket;
  }

  getConnectedObservable(): Observable<boolean> {
    return this.connected;
  }

  getReconnectingObservable(): Observable<boolean> {
    return this.reconnecting;
  }

  private createSocket(): Socket {
    return io(environment.SERVER_URL, {
      query: { 'x-api-key': environment.APIKEY },
      withCredentials: true,
    });
  }

  private bindSocket(): void {
    this.socket.on('connect', async () => {
      // clear buffered requests
      this.socket.sendBuffer = [];
    });
    this.socket.on('connected', async (data: any) => {
      if (this.reconnecting.value == false) {
        this.log.debug('connected');
      } else {
        this.log.debug('reconnected');

        this.gameSettingsService.load();

        this.reconnecting.next(false);
      }

      this.connected.next(true);
    });
    this.socket.on('connect_error', (data: any) => {
      this.log.debug('error. reconnecting..', data);

      this.connected.next(false);
      this.reconnecting.next(true);
    });
    this.socket.on('disconnect', (data: any) => {
      this.log.debug('disconnected', data);

      this.connected.next(false);
      this.reconnecting.next(true);
    });
    this.socket.on('pong', (data: any) => {
      this.log.debug('ping', data);
    });
    this.socket.on('ex', (data: any) => {
      console.error('ex', data);
    });
  }
}
