import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';

const DARKMODE_KEY = 'darkmode';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private darkmode: number = 0;

  private log: Logger;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private logService: LogService
  ) {
    this.log = this.logService.forComponent('service:dark-mode');

    this.platform.ready().then(() => {
      this.storage.create().then(() => {
        this.initialize();
      });
    });
  }

  change(value: number) {
    this.darkmode = value;
    this.onDarkmodeChange(this.darkmode);
  }

  getDarkmode(): number {
    return this.darkmode;
  }

  private initialize(): void {
    if (environment.features.autodarkmode) {
      this.storage.get(DARKMODE_KEY).then((darkmode) => {
        this.setDarkmode(darkmode);
      });
    } else {
      this.log.debug('feature disabled');
    }
  }

  private onDarkmodeChange(darkmode: number): void {
    this.log.debug('' + darkmode);
    this.setDarkmode(darkmode);

    this.storage.set(DARKMODE_KEY, darkmode);
  }

  private setDarkmode(darkmode: number): void {
    this.darkmode = darkmode;

    if (darkmode == 0) {
      this.changeDarkmode(false);
    } else if (darkmode == 1) {
      this.changeDarkmode(true);
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      prefersDark.addEventListener('change', (e) => {
        this.changeDarkmode(e.matches);
      });
      this.changeDarkmode(prefersDark.matches);
    }
  }

  private changeDarkmode(enabled: boolean): void {
    if (enabled) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }
}
