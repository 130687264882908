import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationController, Animation } from '@ionic/angular';

@Component({
  selector: 'app-player-score',
  templateUrl: './player-score.component.html',
  styleUrls: ['./player-score.component.scss'],
})
export class PlayerScoreComponent {
  @Input('score') score: number;
  @ViewChild('digit1000') digit1000Element: ElementRef;
  @ViewChild('digit100') digit100Element: ElementRef;
  @ViewChild('digit10') digit10Element: ElementRef;
  @ViewChild('digit1') digit1Element: ElementRef;

  numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  sign = '';

  constructor(private animationController: AnimationController) {}

  ngAfterViewInit(): void {
    this.animateScroll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.score < 0) {
      this.sign = '-';
    } else {
      this.sign = '';
    }

    if (changes.score) {
      if (changes.score.previousValue != undefined) {
        let diff = Number(changes.score.currentValue) - Number(changes.score.previousValue);

        this.animateScroll();
      }
    }
  }

  animateScroll(): void {
    let s = String(this.score);
    if (this.score < 0) {
      s = s.substring(1);
    }
    const len = s.length;

    const digit1 = len > 0 ? s[len - 1] : 0;
    const digit10 = len > 1 ? s[len - 2] : 0;
    const digit100 = len > 2 ? s[len - 3] : 0;
    const digit1000 = len > 3 ? s[len - 4] : 0;

    const animation1000 = this.createAnimate('1000', this.digit1000Element, Number(digit1000));
    const animation100 = this.createAnimate('100', this.digit100Element, Number(digit100));
    const animation10 = this.createAnimate('10', this.digit10Element, Number(digit10));
    const animation1 = this.createAnimate('1', this.digit1Element, Number(digit1));

    this.animationController
      .create()
      .addAnimation([animation1000, animation100, animation10, animation1])
      .play();
  }

  createAnimate(id: string, digitElement: ElementRef, num: number): Animation {
    const keyframes = [];
    for (let n of this.numbers) {
      keyframes.push({
        offset: (n + 1) / this.numbers.length,
        transform: 'translateY(-' + n * 40 + 'px)',
      });
    }

    const scroll = this.animationController
      .create('player-score-scroll-' + id)
      .addElement(digitElement.nativeElement)
      .easing('cubic-bezier(0.3, -0.3, 1, 0)')
      .duration(400)
      .iterations(1)
      .keyframes(keyframes)
      .onFinish(() => {
        this.animationController
          .create('player-score-reveal-' + id)
          .addElement(digitElement.nativeElement)
          .duration(40)
          .to('transform', 'translateY(-' + num * 40 + 'px)')
          .play();
      });
    return scroll;
  }
}
