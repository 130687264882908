import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationController, PopoverController } from '@ionic/angular';
import { Joker, JokerCategory } from 'projects/models/src/public-api';
import { JokerPopoverComponent } from '../joker-popover/joker-popover.component';

@Component({
  selector: 'app-joker-icon',
  templateUrl: './joker-icon.component.html',
  styleUrls: ['./joker-icon.component.scss'],
})
export class JokerIconComponent {
  @Input('jokers') jokers: Array<Joker>;
  @ViewChild('jokerElement', { read: ElementRef }) jokerElement: ElementRef;

  jokerRingSingle = false;
  jokerRingRound = false;

  constructor(
    private animationController: AnimationController,
    private popoverController: PopoverController
  ) {}

  ngAfterViewInit(): void {
    this.animateNotifyJoker('var(--ion-color-primary-contrast-rgb)');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jokers) {
      const [currJokersSingle, currJokersRound] = this.countJokers(changes.jokers.currentValue);

      // received joker
      if (
        changes.jokers.previousValue &&
        changes.jokers.currentValue?.length > changes.jokers.previousValue?.length
      ) {
        const [prevJokersSingle, prevJokersRound] = this.countJokers(changes.jokers.previousValue);

        if (currJokersSingle > prevJokersSingle) {
          this.animateNotifyJoker('var(--ion-color-success-rgb)');
        }
        if (currJokersRound > prevJokersRound) {
          this.animateNotifyJoker('var(--ion-color-warning-rgb)');
        }
      }
      // used joker
      else if (
        changes.jokers.previousValue &&
        changes.jokers.currentValue?.length < changes.jokers.previousValue?.length
      ) {
        this.animateNotifyJoker('var(--ion-color-primary-contrast-rgb)');
      }

      this.jokerRingSingle = currJokersSingle > 0;
      this.jokerRingRound = currJokersRound > 0;
    }
  }

  async presentJokerPopover(ev: any): Promise<void> {
    if (this.jokers?.length > 0) {
      this.animateClickJoker();

      const popover = await this.popoverController.create({
        component: JokerPopoverComponent,
        event: ev,
        translucent: true,
        componentProps: { jokers: this.jokers },
      });
      return popover.present();
    }
  }

  private countJokers(jokers: Array<Joker>): number[] {
    const singles = jokers.filter((j) => j.category == JokerCategory.single)?.length;
    const rounds = jokers.filter((j) => j.category == JokerCategory.round)?.length;

    return [singles, rounds];
  }

  private animateNotifyJoker(color: string): void {
    const animation = this.animationController
      .create('joker-notify')
      .addElement(this.jokerElement.nativeElement)
      .duration(600)
      .keyframes([
        { offset: 0, transform: 'scale(0.9)', boxShadow: '0 0 0 0 rgba(' + color + ', 0.7)' },
        { offset: 0.7, transform: 'scale(1)', boxShadow: '0 0 0 15px rgba(' + color + ', 0)' },
        { offset: 1, transform: 'scale(1)', boxShadow: '0 0 0 0 rgba(' + color + ', 0)' },
      ]);
    animation.play();
  }

  private animateClickJoker(): void {
    const animation = this.animationController
      .create('joker-use')
      .addElement(this.jokerElement.nativeElement)
      .duration(100)
      .keyframes([
        { offset: 0, transform: 'scale(0.9) rotate(15deg)' },
        { offset: 0.7, transform: 'scale(1) rotate(30deg)' },
        { offset: 1, transform: 'scale(1) rotate(0)' },
      ]);
    animation.play();
  }
}
