<div class="answer-box">
  <button
    *ngFor="let a of answers"
    [disabled]="!round.running"
    [ngClass]="a.class"
    (click)="selectAnswer(a)"
    class="button-style answer-button ion-activatable"
  >
    <span class="inner" mode="spaceout">
      <label class="answer-letter">{{ a.letter }}</label>
      <span>{{ a.text }}</span>
      <ion-badge *ngFor="let aBy of a.players" color="warning">x</ion-badge>
    </span>
    <ion-ripple-effect></ion-ripple-effect>
  </button>
</div>
