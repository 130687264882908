<ion-content [fullscreen]="true">
  <header>
    <ion-toolbar>
      <ion-title>
        <ion-icon name="heart-outline"></ion-icon>
        <ion-label class="category-title">Feedback</ion-label>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </header>

  <section>
    <ion-card>
      <ion-card-content class="join-game-content">
        <form [formGroup]="feedbackForm" (ngSubmit)="send()" novalidate>
          <ion-list>
            <ion-item>
              <ion-input formControlName="name" label="Name" labelPlacement="floating" type="text"></ion-input>
            </ion-item>
            <div
              class="form--error-message"
              *ngIf="!feedbackForm.controls.name.valid  && (feedbackForm.controls.name.dirty || isSubmitted)"
            >
              <ion-icon name="information-circle-outline"></ion-icon>
              Enter your name
            </div>

            <ion-item>
              <ion-toggle formControlName="playAgain">Would you play the game again?</ion-toggle>
            </ion-item>

            <ion-item>
              <ion-toggle formControlName="moreGameModes">More game modes?</ion-toggle>
            </ion-item>

            <ion-item>
              <ion-toggle formControlName="moreChallengingQuestions">More challenging questions?</ion-toggle>
            </ion-item>

            <ion-item>
              <ion-textarea
                formControlName="text"
                label="Feedback"
                labelPlacement="floating"
                placeholder="Enter your feedback e.g. theme, questions, game modes etc."
                rows="4"
                cols="20"
              ></ion-textarea>
            </ion-item>
          </ion-list>

          <button class="button-style ion-activatable" type="submit" expand="block">
            <span class="inner">
              <ion-icon slot="start" name="heart-outline"></ion-icon>
              <span>Send Feedback</span>
            </span>
            <ion-ripple-effect></ion-ripple-effect>
          </button>
        </form>
      </ion-card-content>
    </ion-card>
  </section>
</ion-content>
