<section class="popover-content">
  <div class="category-title">Quickreact</div>

  <div class="emoji-content">
    <ul class="emoji-list">
      <li (click)="drawEmoji(128512)">
        <span>{{ codePointToString(128512) }}</span>
      </li>
      <li (click)="drawEmoji(128523)">
        <span>{{ codePointToString(128523) }}</span>
      </li>
      <li (click)="drawEmoji(128557)">
        <span>{{ codePointToString(128557) }}</span>
      </li>
      <li (click)="drawEmoji(128293)">
        <span>{{ codePointToString(128293) }}</span>
      </li>
      <li (click)="drawEmoji(128169)">
        <span>{{ codePointToString(128169) }}</span>
      </li>
      <li (click)="drawEmoji(127867)">
        <span>{{ codePointToString(127867) }}</span>
      </li>

      <li (click)="drawEmoji(128077)">
        <span>{{ codePointToString(128077) }}</span>
      </li>
      <li (click)="drawEmoji(128078)">
        <span>{{ codePointToString(128078) }}</span>
      </li>
    </ul>
  </div>
</section>
