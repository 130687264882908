import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Update } from 'projects/models/src/public-api';
import { BehaviorSubject, Observable } from 'rxjs';

import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private log: Logger;

  private readonly updating: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly updated: BehaviorSubject<Update> = new BehaviorSubject(null);

  constructor(private swUpdate: SwUpdate, private logService: LogService) {
    this.log = this.logService.forComponent('service:pwa');

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            this.log.debug('Downloading new version ', event.version.hash);

            this.updating.next(true);
            this.updated.next(null);
            break;
          case 'VERSION_READY': {
            this.log.debug('Current version ', event.currentVersion.hash);
            this.log.debug('Updated version ', event.latestVersion.hash);

            this.updated.next(new Update(true, event.latestVersion.hash));
            this.updating.next(false);
            break;
          }
          case 'VERSION_INSTALLATION_FAILED': {
            this.log.debug('Update failed ', event.error);

            this.updated.next(new Update(false, event.error));
            this.updating.next(false);
            break;
          }
        }
      });
    } else {
      this.log.debug('Serviceworker disabled (no PWA)');

      this.updated.next(new Update(null, null));
    }
  }

  getUpdatingObservable(): Observable<boolean> {
    return this.updating;
  }

  getUpdatedObservable(): Observable<Update> {
    return this.updated;
  }
}
