<ion-content>
  <header>
    <ion-toolbar>
      <ion-title>
        <ion-icon name="refresh-circle-outline"></ion-icon>
        <ion-label class="category-title">Forgot</ion-label>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </header>

  <section>
    <ion-card>
      <ion-card-content *ngIf="!success" class="forgot-content">
        <form [formGroup]="forgotForm" (ngSubmit)="forgot()" novalidate>
          <ion-item lines="full">
            <ion-input formControlName="account" label="Username / E-mail" labelPlacement="floating" type="text"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!forgotForm.controls.account.valid && (forgotForm.controls.account.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter your Username or E-mail address
          </div>

          <button class="button-style ion-activatable" type="submit" expand="block">
            <span class="inner">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              <span>Reset</span>
            </span>
            <ion-ripple-effect></ion-ripple-effect>
          </button>
        </form>

        <ion-item class="item--login">
          <ion-label class="ion-text-center">
            Remember?
            <a (click)="go2Login()">Login</a>
          </ion-label>
        </ion-item>
      </ion-card-content>

      <ion-card-content *ngIf="success" class="complete-content">
          <h1>Congratulations 🎉</h1>
          <div>You are one step away..</div>
          <div>Check your E-Mails and follow the link.</div>
      </ion-card-content>
    </ion-card>
  </section>
</ion-content>
