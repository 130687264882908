import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private log: Logger;

  constructor(private logService: LogService) {
    this.log = this.logService.forComponent('service:startup');
  }

  initialize(): Promise<any> {
    return new Promise((resolve) => {
      if (environment.features.debug) {
        this.logService.enable(String(environment.features.debug));
        this.log.debug('debug active');
      } else {
        this.logService.disable;
      }

      resolve(true);
    });
  }
}
