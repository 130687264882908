import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorApiService, Logger } from 'projects/services/src/public-api';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private log: Logger;

  constructor(private errorApiService: ErrorApiService) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);
    this.errorApiService.send(error);
  }
}
