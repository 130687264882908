import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { Player } from 'projects/models/src/public-api';
import { GameStateService } from 'projects/services/src/public-api';
import { EmojiPopoverComponent } from '../emoji-popover/emoji-popover.component';

enum Mode {
  'full',
  'limited',
  'mini',
}

@Component({
  selector: 'app-player-card',
  templateUrl: './player-card.component.html',
  styleUrls: ['./player-card.component.scss'],
})
export class PlayerCardComponent implements OnInit {
  @Input() mode: string;
  @Input() player: Player;

  constructor(
    public gameStateService: GameStateService,
    public popoverController: PopoverController
  ) {}

  ngOnInit(): void {}

  async presentEmojiPopover(ev: any): Promise<void> {
    if (this.gameStateService.isCurrentPlayer(this.player)) {
      const popover = await this.popoverController.create({
        component: EmojiPopoverComponent,
        event: ev,
        translucent: true,
      });
      return popover.present();
    }
  }

  getPlayerScore(): number {
    if (this.gameStateService.getRound().scores) {
      for (const playerScore of this.gameStateService.getRound().scores) {
        if (this.player.id == playerScore.player) {
          return playerScore.score;
        }
      }
    }
    return null;
  }

  isPositiveScore(): boolean {
    return Number(this.getPlayerScore()) > 0;
  }
}
