import { Component, ElementRef, ViewChild } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-emoji-effect',
  templateUrl: './emoji-effect.component.html',
  styleUrls: ['./emoji-effect.component.scss'],
})
export class EmojiEffectComponent {
  @ViewChild('modal', { read: ElementRef }) modal: ElementRef;
  @ViewChild('canvas', { read: ElementRef }) canvas: ElementRef;

  queue: Array<any> = new Array();

  constructor(private animationController: AnimationController) {}

  drawEmoji(codePoint: number): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.queue.length == 0) {
        this.fadeIn();
      }

      for (let i = 0; i < 15; ++i) {
        const size = EmojiEffectComponent.getRandomInt(20, 100);
        const emoji = this.createEmoji(codePoint, size);
        this.canvas.nativeElement.appendChild(emoji);

        const anime = this.createAnimation(emoji, size);
        anime.onFinish(() => {
          this.canvas.nativeElement.removeChild(emoji);
        });
        anime.play();
      }
      this.queue.push(codePoint);

      setTimeout(() => {
        if (this.queue.length > 1) {
          this.queue.pop();
          resolve();
        } else {
          this.fadeOut().then(() => {
            resolve();
          });
        }
      }, 2800);
    });
  }

  private fadeIn(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.modal.nativeElement.style.display = 'flex';
      this.animationController
        .create('fade-in')
        .addElement(this.modal.nativeElement)
        .duration(200)
        .keyframes([
          { offset: 0, opacity: 0 },
          { offset: 1, opacity: 1 },
        ])
        .onFinish(() => {
          resolve();
        })
        .play();
    });
  }

  private fadeOut(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.animationController
        .create('fade-out')
        .addElement(this.modal.nativeElement)
        .duration(200)
        .keyframes([
          { offset: 0, opacity: 1 },
          { offset: 1, opacity: 0 },
        ])
        .onFinish(() => {
          this.modal.nativeElement.style.display = 'none';
          resolve();
        })
        .play();
    });
  }

  private createAnimation(element: HTMLElement, size: number) {
    const animation = this.animationController
      .create('emoji-effect')
      .addElement(element)
      .delay(EmojiEffectComponent.getRandomInt(0, 1000))
      .duration(EmojiEffectComponent.getRandomInt(1500, 2000))
      .easing('ease-in')
      .keyframes([
        { offset: 0, transform: 'translateY(0)', opacity: 1 },
        { offset: 0.5, transform: 'translateY(-50vh)', opacity: 1 },
        { offset: 1, transform: 'translateY(calc(-100vh - ' + size + 'px))', opacity: 0.2 },
      ]);
    return animation;
  }

  private createEmoji(codePoint: number, size: number): HTMLElement {
    const emoji = document.createElement('div');
    emoji.innerText = String.fromCodePoint(codePoint);
    emoji.style.position = 'absolute';
    emoji.style.fontSize = size + 'px';
    emoji.style.left = EmojiEffectComponent.getRandomInt(-5, 100) + '%';
    emoji.style.bottom = size * -2 + 'px';
    return emoji;
  }

  private static getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
