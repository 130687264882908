import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Player } from 'projects/models/src/public-api';
import { GameService, GameStateService, MessageService } from 'projects/services/src/public-api';

@Component({
  selector: 'app-game-header',
  templateUrl: './game-header.component.html',
  styleUrls: ['./game-header.component.scss'],
})
export class GameHeaderComponent {
  @Input('player') player: Player;
  @Input('players') players: Array<Player>;
  @Input('spectators') spectators: Array<Player>;

  constructor(
    private router: Router,
    private gameService: GameService,
    private messageService: MessageService,
    private menu: MenuController
  ) {}

  exitGame() {
    this.messageService
      .presentAlert('Do you really want to quit the running game?')
      .then((answer) => {
        if (answer) {
          this.gameService.leave();
          this.go2Home();
        }
      });
  }

  toggleMenu() {
    this.menu.toggle('players');
  }

  go2Home(): void {
    this.router.navigate(['home']);
  }
}
