import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public modalCtrl: ModalController) {}

  async presentModal(page: any) {
    const top = await this.modalCtrl.getTop();
    if (top) {
      this.dismissModal();
    }

    const modal = await this.modalCtrl.create({
      component: page,
    });
    return await modal.present();
  }

  async dismissModal() {
    return this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
