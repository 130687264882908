import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, MessageService } from 'projects/services/src/public-api';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.page.html',
  styleUrls: ['./verify.page.scss'],
})
export class VerifyPage implements OnInit {
  success: boolean = false;
  failure: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.email && params.verification) {
        this.verify(params.email, params.verification);
      } else {
        this.failure = true;
      }
    });
  }

  private verify(email: String, verification: String): void {
    this.authService
      .verify(email, verification)
      .then((success) => {
        if (success) {
          this.success = true;
          setTimeout(() => {
            this.go2Home();
          }, 5000);
        } else {
          this.failure = true;
        }
      })
      .catch(() => {
        this.failure = true;
      });
  }

  private go2Home() {
    this.router.navigate(['home']);
  }
}
