import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private alertController: AlertController, private toastCtrl: ToastController) {}

  presentAlert(message: string): Promise<any> {
    return new Promise((resolve) => {
      this.alertController
        .create({
          message: message,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                resolve(false);
              },
            },
            {
              text: 'Okay',
              handler: () => {
                resolve(true);
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  presentToast(message: string): Promise<any> {
    return new Promise((resolve) => {
      this.toastCtrl
        .create({
          message: message,
          duration: 2000,
        })
        .then((toast) => {
          toast.onDidDismiss().then(resolve);
          toast.present();
        });
    });
  }

  presentErrorToast(message: string): Promise<any> {
    return new Promise((resolve) => {
      this.toastCtrl
        .create({
          header: 'Error',
          message: message,
          duration: 2000,
          cssClass: 'toast--error',
          buttons: [
            {
              side: 'start',
              icon: 'bug-outline',
            },
          ],
        })
        .then((toast) => {
          toast.onDidDismiss().then(resolve);
          toast.present();
        });
    });
  }

  presentJokerToast(message: string): Promise<any> {
    return new Promise((resolve) => {
      this.toastCtrl
        .create({
          message: message,
          duration: 2000,
          cssClass: 'toast--joker',
          buttons: [
            {
              side: 'start',
              icon: 'bonfire-outline',
            },
          ],
        })
        .then((toast) => {
          toast.onDidDismiss().then(resolve);
          toast.present();
        });
    });
  }
}
