import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Feedback } from '../../../projects/models/src/lib/api/feedback.model';
import { GameStateService, MessageService } from 'projects/services/src/public-api';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit, OnDestroy {
  feedbackForm: UntypedFormGroup;
  isSubmitted: boolean = false;

  unsubscribe = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private gameStateService: GameStateService
  ) {
    this.feedbackForm = this.formBuilder.group({
      name: [this.gameStateService.getCurrentPlayer().name, [Validators.required]],
      playAgain: [false, []],
      moreGameModes: [false, []],
      moreChallengingQuestions: [false, []],
      text: ['', []],
    });
  }

  ngOnInit() {
    this.gameStateService
      .getCurrentPlayerObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((player) => {
        let name = '';
        if (player) {
          name = player.name;
        }

        this.feedbackForm.controls.name.setValue(name);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  send() {
    this.isSubmitted = true;
    if (this.feedbackForm.valid) {
      const feedback: Feedback = new Feedback();
      feedback.name = this.feedbackForm.value.name;
      feedback.playAgain = this.feedbackForm.value.playAgain;
      feedback.moreGameModes = this.feedbackForm.value.moreGameModes;
      feedback.moreChallengingQuestions = this.feedbackForm.value.moreChallengingQuestions;
      feedback.text = this.feedbackForm.value.text;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      };

      this.httpClient
        .post(environment.SERVER_URL + 'api/feedbacks', feedback, httpOptions)
        .subscribe(
          (data) => {
            this.messageService.presentToast('Feedback Received. Thank you &#128591;');
            this.dismissModal();
          },
          (error) => {
            console.error(error);
            this.messageService.presentToast('Feedback Failed!');
          }
        );
    } else {
      return false;
    }
  }

  async dismissModal() {
    this.modalService.dismissModal();
  }
}
