import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Token, User } from 'projects/models/src/public-api';
import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';
import { UserGame } from 'projects/models/src/lib/api/user-game.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private log: Logger;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient, private logService: LogService) {
    this.log = this.logService.forComponent('service:user-api');
  }

  login(username: String, password: String): Observable<Token> {
    return this.httpClient.post<Token>(
      environment.SERVER_URL + 'api/tokens',
      { username: username, password: password },
      this.httpOptions
    );
  }

  register(user: User): Observable<User> {
    return this.httpClient.post<User>(
      environment.SERVER_URL + 'api/users',
      { username: user.username, email: user.email, password: user.password },
      this.httpOptions
    );
  }

  reset(id: String, reset: String, password: String): Observable<User> {
    return this.httpClient.post<User>(
      environment.SERVER_URL + 'api/users/reset/' + id + '/' + reset,
      {
        password: password,
      },
      this.httpOptions
    );
  }

  verify(email: String, verification: String): Observable<User> {
    return this.httpClient.get<User>(
      environment.SERVER_URL + 'api/users/verify/' + email + '/' + verification,
      this.httpOptions
    );
  }

  forgot(account: String): Observable<void> {
    return this.httpClient.post<any>(
      environment.SERVER_URL + 'api/users/forgot',
      { account: account },
      this.httpOptions
    );
  }

  update(user: User): Observable<User> {
    return this.httpClient.patch<User>(
      environment.SERVER_URL + 'api/users/' + user.id,
      {
        email: user.email,
        password: user.password,
        handle: user.data.handle,
        picture: user.data.picture,
      },
      this.httpOptions
    );
  }

  postPicture(user: User, picture: File) {
    const formData = new FormData();
    formData.append('picture', picture);
    formData.append('avatar', picture.name);
    return this.httpClient.put(
      environment.SERVER_URL + 'api/users/' + user.id + '/picture',
      formData,
      this.httpOptions
    );
  }

  getGames(id: string): Observable<UserGame[]> {
    return this.httpClient.get<UserGame[]>(
      environment.SERVER_URL + 'api/users/' + id + '/games',
      this.httpOptions
    );
  }
}
