import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationController, PopoverController } from '@ionic/angular';
import { Player } from 'projects/models/src/public-api';
import { EmojiPopoverComponent } from '../emoji-popover/emoji-popover.component';

@Component({
  selector: 'app-player-icon',
  templateUrl: './player-icon.component.html',
  styleUrls: ['./player-icon.component.scss'],
})
export class PlayerIconComponent {
  @Input('player') player: Player;
  @ViewChild('playerElement', { read: ElementRef }) playerElement: ElementRef;

  constructor(
    private animationController: AnimationController,
    private popoverController: PopoverController
  ) {}

  ngAfterViewInit(): void {
    this.animateNotifyPlayer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.player) {
      if (
        changes.player.previousValue &&
        changes.player.previousValue.score != changes.player.currentValue.score
      ) {
        setTimeout(() => {
          this.animateNotifyPlayer();
        }, 200);
      }
    }
  }

  async presentEmojiPopover(ev: any): Promise<void> {
    this.animateClickPlayer();

    const popover = await this.popoverController.create({
      component: EmojiPopoverComponent,
      event: ev,
      translucent: true,
    });
    return popover.present();
  }

  private animateNotifyPlayer(): void {
    const color = 'var(--player' + this.player.number + '-rgb)';

    const animation = this.animationController
      .create('player-notify')
      .addElement(this.playerElement.nativeElement)
      .duration(600)
      .keyframes([
        { offset: 0, transform: 'scale(0.9)', boxShadow: '0 0 0 0 rgba(' + color + ', 0.7)' },
        { offset: 0.7, transform: 'scale(1)', boxShadow: '0 0 0 15px rgba(' + color + ', 0)' },
        { offset: 1, transform: 'scale(1)', boxShadow: '0 0 0 0 rgba(' + color + ', 0)' },
      ]);
    animation.play();
  }

  private animateClickPlayer(): void {
    const animation = this.animationController
      .create('joker-use')
      .addElement(this.playerElement.nativeElement)
      .duration(100)
      .keyframes([
        { offset: 0, transform: 'scale(0.9) rotate(0deg)' },
        { offset: 0.7, transform: 'scale(1) rotate(15deg)' },
        { offset: 1, transform: 'scale(1) rotate(0)' },
      ]);
    animation.play();
  }
}
