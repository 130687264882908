export async function readBuildInformation() {
  return new Promise<string>((resolve) => {
    fetch('/assets/buildinformation.json')
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        resolve(json.version);
      })
      .catch((e) => {
        resolve('n/a');
      });
  });
}
