<section class="popover-content">
  <div class="category-title">Joker</div>

  <div class="joker-content">
    <ul class="joker-list">
      <li
        *ngFor="let joker of jokers"
        [ngClass]="{
          single: joker.category == 'single',
          round: joker.category == 'round'
        }"
        (click)="useJoker(joker)"
      >
        <app-joker-badge [icon]="joker.icon"></app-joker-badge>
        <span>{{ joker.name }}</span>
      </li>
    </ul>
  </div>
</section>
