<ion-toolbar class="toolbar-header" transparent>
  <ion-buttons slot="start">
    <ion-menu-button (click)="toggleMenu()"></ion-menu-button>
  </ion-buttons>

  <div class="logo">
    <a (click)="go2Home()" fill="clear">
      <img src="/assets/doge.vector.svg" alt="logo" width="50" height="50" />
    </a>
  </div>

  <ion-buttons slot="end">
    <ion-button *ngIf="!isInLobby && isLoggedIn" (click)="go2Profile()">
      <ion-icon slot="icon-only" name="person-circle-outline"></ion-icon>
    </ion-button>
    <ion-button *ngIf="!isInLobby && !isLoggedIn" (click)="presentLoginModal()">
      <ion-icon slot="icon-only" name="person-circle-outline"></ion-icon>
    </ion-button>

    <ion-fab-button *ngIf="isInLobby" class="fab-in-lobby" size="small" (click)="go2Lobby()">
      <ion-spinner name="crescent"></ion-spinner>
      <ion-icon name="game-controller-outline"></ion-icon>
    </ion-fab-button>
  </ion-buttons>
</ion-toolbar>

<ion-menu side="start" menuId="navigation" contentId="main">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button auto-hide="false"></ion-menu-button>
    </ion-buttons>

    <ion-buttons *ngIf="isLoggedIn" slot="end">
      <ion-menu-toggle>
        <ion-button (click)="go2Profile()" class="button-fullheight">
          <ion-icon name="person-circle-outline"></ion-icon>
          {{ user.username }}
        </ion-button>
        <ion-button (click)="logout()" class="button-fullheight">
          <ion-icon color="danger" name="exit-outline"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>

  <ion-content fullscreen>
    <div class="wrapper-lists">
      <ion-list lines="full">
        <ion-menu-toggle>
          <ion-item (click)="go2Home()" button>
            <ion-icon name="game-controller-outline" slot="start"></ion-icon>
            Home
          </ion-item>
          <ion-item *ngIf="!isLoggedIn" (click)="presentRegisterModal()" button>
            <ion-icon name="person-add-outline" slot="start"></ion-icon>
            Register
          </ion-item>
          <ion-item *ngIf="!isLoggedIn" (click)="presentLoginModal()" button>
            <ion-icon name="lock-open-outline" slot="start"></ion-icon>
            Login
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list lines="full">
        <ion-menu-toggle>
          <ion-item (click)="presentFeedbackModal()" button>
            <ion-icon icon="heart-outline" slot="start"></ion-icon>
            Feedback
          </ion-item>
          <ion-item (click)="presentThanksModal()" button>
            <ion-icon icon="beer-outline" slot="start"></ion-icon>
            Special Thanks
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list lines="full" class="list--grow">
        <ion-menu-toggle>
          <ion-item (click)="openBrowserLink('https://play-qes.de/')" button>
            <ion-icon icon="link-outline" slot="start"></ion-icon>
            Website
          </ion-item>
          <ion-item (click)="openBrowserLink('https://www.instagram.com/playqes/')" button>
            <ion-icon icon="logo-instagram" slot="start"></ion-icon>
            Follow us on Instagram!
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list class="list--version">
        <ion-item button="false" lines="none">
          <ion-icon icon="information-circle-outline"></ion-icon>
          {{ version }}
        </ion-item>
      </ion-list>
    </div>
  </ion-content>
</ion-menu>
