import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'flatted';

import { environment } from 'src/environments/environment';
import { Logger, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorApiService {
  private log: Logger;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient, private logService: LogService) {
    this.log = this.logService.forComponent('service:error-api');
  }

  send(error: any) {
    this.httpClient
      .post(environment.SERVER_URL + 'api/errors', stringify(error), this.httpOptions)
      .subscribe({
        next: (_) => {
          this.log.debug('Error sent');
        },
        error: (error) => {
          this.log.debug('Error sending error', error);
        },
      });
  }
}
