import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QRCodeModule } from 'angularx-qrcode';

import { AnswerBoxComponent } from 'projects/components/src/lib/answer-box/answer-box.component';
import { EmojiEffectComponent } from 'projects/components/src/lib/emoji-effect/emoji-effect.component';
import { GameScreenComponent } from 'projects/components/src/lib/game-screen/game-screen.component';
import { JokerBadgeComponent } from 'projects/components/src/lib/joker-badge/joker-badge.component';
import { PlayerCardComponent } from 'projects/components/src/lib/player-card/player-card.component';
import { QuestionCountdownComponent } from 'projects/components/src/lib/question-countdown/question-countdown.component';
import { WaitingScreenComponent } from 'projects/components/src/lib/waiting-screen/waiting-screen.component';
import { EmojiPopoverComponent } from 'projects/components/src/lib/emoji-popover/emoji-popover.component';
import { JokerIconComponent } from 'projects/components/src/lib/joker-icon/joker-icon.component';
import { JokerPopoverComponent } from 'projects/components/src/lib/joker-popover/joker-popover.component';
import { PlayerIconComponent } from 'projects/components/src/lib/player-icon/player-icon.component';
import { PlayerScoreComponent } from 'projects/components/src/lib/player-score/player-score.component';
import { QuestionBoxModule } from 'projects/components/src/lib/question-box/question-box.module';
import { SwiperDirective } from 'projects/directives/src/lib/swiper.directive';

@NgModule({
  declarations: [
    AnswerBoxComponent,
    EmojiEffectComponent,
    EmojiPopoverComponent,
    GameScreenComponent,
    JokerBadgeComponent,
    JokerIconComponent,
    JokerPopoverComponent,
    PlayerCardComponent,
    PlayerIconComponent,
    PlayerScoreComponent,
    QuestionCountdownComponent,
    WaitingScreenComponent,
    SwiperDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    QuestionBoxModule
  ],
  exports: [
    AnswerBoxComponent,
    EmojiEffectComponent,
    EmojiPopoverComponent,
    GameScreenComponent,
    JokerBadgeComponent,
    JokerIconComponent,
    JokerPopoverComponent,
    PlayerCardComponent,
    PlayerIconComponent,
    PlayerScoreComponent,
    QuestionCountdownComponent,
    WaitingScreenComponent,
    SwiperDirective,
  ],
})
export class SharedModule {}
