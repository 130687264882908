import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'projects/models/src/public-api';

import { AuthService, MessageService } from 'projects/services/src/public-api';
import { LoginPage } from '../login/login.page';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.page.html',
  styleUrls: ['./forgot.page.scss'],
})
export class ForgotPage {
  forgotForm: UntypedFormGroup;
  isSubmitted: boolean = false;
  success: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService
  ) {
    this.forgotForm = this.formBuilder.group({
      account: ['', [Validators.required]],
    });
  }

  forgot() {
    this.isSubmitted = true;
    if (this.forgotForm.valid) {
      this.authService
        .forgot(this.forgotForm.value.account)
        .then((success) => {
          if (success) {
            this.success = true;

            setTimeout(() => {
              this.dismissModal();
            }, 5000);
          } else {
            this.messageService.presentToast('Reset Failed &#128558;');
          }
        })
        .catch(() => {
          this.messageService.presentToast('Reset Failed &#128558;');
        });
    } else {
      return false;
    }
  }

  async dismissModal() {
    this.modalService.dismissModal();
  }

  go2Login(): void {
    this.modalService.presentModal(LoginPage);
  }
}
