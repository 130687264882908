export class User {
  id: string;
  username: string;
  email: string;
  password?: string;
  data: {
    handle: string;
    picture?: string;
  };
  token?: string;

  constructor() {
    this.data = { handle: null };
  }
}
