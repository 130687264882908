<div [ngClass]="questionClass" class="questionBox">
  <div class="question" #question>
    <div *ngIf="round.id" class="category">
      <ion-badge *ngIf="round.question?.category" [ngStyle]="{ background: categoryColor }">
        {{ round.question.category }}
      </ion-badge>
    </div>

    <div *ngIf="round.id" class="countdown">
      <app-question-countdown
        [time]="time"
        [countdown]="gameConfig.roundtime / 1000"
      ></app-question-countdown>
    </div>

    <div *ngIf="round.id" class="rounds">
      <ion-badge color="tertiary">
        {{ round.id }} /
        {{ gameConfig.rounds }}
      </ion-badge>
    </div>

    <div [ngStyle]="{ 'font-size': questionFontSize }" class="text">
      {{ round.question?.text }}
    </div>

    <div *ngIf="round.question?.points" class="points">
      <ion-badge color="warning">+{{ round.question.points }}</ion-badge>
    </div>

    <!-- CATEGORY: MUSIC -->
    <div *ngIf="round.question?.song" (click)="toggleAudio()" class="audio">
      <ion-icon *ngIf="audioPlaying" name="volume-high-outline"></ion-icon>
      <ion-icon *ngIf="!audioPlaying" name="volume-mute-outline" #audiomuted></ion-icon>
    </div>

    <div *ngIf="round.modifiers" class="modifiers">
      <ion-badge *ngIf="round.modifiers.doublescore" color="danger">x2 / negate</ion-badge>
    </div>
  </div>

  <!-- CATEGORY: MOVIE with SCREENSHOTS -->
  <swiper-container appSwiper *ngIf="round.question?.images" [config]="slideOptsImages" init="false" #slides>
    <swiper-slide *ngFor="let s of round.question?.images">
      <img [src]="s" class="questionMovieScreenshotsBox" />
    </swiper-slide>
  </swiper-container>
</div>
