import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AnimationController } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GameConfig } from 'projects/models/src/public-api';
import {
  GameService,
  GameSettingsService,
  GameStateService,
  MessageService,
} from 'projects/services/src/public-api';
import { Mode } from 'projects/models/src/lib/game-mode.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-waiting-screen',
  templateUrl: './waiting-screen.component.html',
  styleUrls: ['./waiting-screen.component.scss'],
})
export class WaitingScreenComponent implements OnInit, OnDestroy {
  @Input() frontendUrl: string;
  @ViewChild('qrcode', { read: ElementRef }) qrcode: ElementRef;

  unsubscribe = new Subject<void>();

  joinUrl: string = environment.FRONTEND_URL;

  hostForm: UntypedFormGroup;
  customizeGame: boolean = false;
  isSubmitted: boolean = false;

  allModes: Mode[] = new Array();
  subModes: string[] = new Array();
  modeColor: string;

  countdownInterval: any;
  time: number = 1;

  constructor(
    private animationController: AnimationController,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private gameService: GameService,
    private gameSettingsService: GameSettingsService,
    public gameStateService: GameStateService,
    private messageService: MessageService
  ) {
    this.hostForm = this.formBuilder.group({
      playerName: [this.gameStateService.getCurrentPlayer().name, [Validators.required]],
      mode: [this.gameStateService.getGame().config.mode, [Validators.required]],
      submode: [this.gameStateService.getGame().config.submode, [Validators.required]],
      jokers: [this.gameStateService.getGame().config.jokers, []],
      rounds: [this.gameStateService.getGame().config.rounds, []],
      roundtime: [this.gameStateService.getGame().config.roundtime / 1000, []],
    });
  }

  ngOnInit(): void {
    this.gameSettingsService
      .getSettingsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((settings) => {
        if (settings) {
          this.allModes = settings.gameModes;
        }
      });

    this.gameStateService
      .getCurrentPlayerObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((player) => {
        if (player) {
          this.hostForm.controls.playerName.setValue(player.name);
        }
      });

    this.gameStateService
      .getGameObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((game) => {
        if (game) {
          this.joinUrl = this.frontendUrl + 'game-box?gameId=' + game.id;
          this.modeColor = this.updateGameModeState(game.config.mode, game.config.submode);

          this.hostForm.controls.mode.setValue(game.config.mode);
          this.hostForm.controls.submode.setValue(game.config.submode);
          this.hostForm.controls.jokers.setValue(game.config.jokers);
          this.hostForm.controls.rounds.setValue(game.config.rounds);
          this.hostForm.controls.roundtime.setValue(game.config.roundtime / 1000);
        }
      });

    // LEFT
    this.gameService
      .leftGame()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((left) => {
        if (this.gameStateService.isCurrentPlayer(left.player)) {
          this.messageService.presentToast('You were left behind &#128533;');
          this.router.navigate(['home']);
        }
      });

    // STARTED
    this.gameService
      .startedGame()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.resetCountdown();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onEnter(): void {
    this.startCountdown();
  }

  onLeave(): void {
    this.resetCountdown();
  }

  onChangeMode(event): void {
    this.updateGameModeState(event.detail.value);
  }

  updateGameModeState(mode: string, submode?: string): string {
    let color = this.modeColor;
    const gameMode = this.allModes.filter((m) => m.name == mode)?.[0];
    if (gameMode) {
      color = gameMode.color;
      this.subModes = new Array('all').concat(gameMode.submodes.map((s) => s.name));

      if (submode) {
        const subMode = gameMode.submodes.filter((s) => s.name == submode)?.[0];
        if (subMode) {
          color = subMode.color;
          this.hostForm.controls.submode.setValue(submode);
        }
      } else {
        this.hostForm.controls.submode.setValue(this.subModes[0]);
      }
    }
    return color;
  }

  startCountdown(): void {
    this.resetCountdown();

    // adjust timer based on time left in round
    const timeToAdjustTo = this.gameStateService.getGame().finishedTime
      ? this.gameStateService.getGame().finishedTime
      : this.gameStateService.getGame().createdTime;
    if (timeToAdjustTo) {
      const millisLeft = new Date().getTime() - Date.parse(timeToAdjustTo);
      const secondsLeft = Math.floor(millisLeft / 1000);
      this.time =
        1 - secondsLeft * (100 / (this.gameStateService.getGame().config.idletime / 1000) / 100);
    }

    this.countdownInterval = setInterval(() => {
      this.time -= 100 / (this.gameStateService.getGame().config.idletime / 1000) / 100;
      if (this.time <= 0) {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }

  resetCountdown(): void {
    clearInterval(this.countdownInterval);
  }

  toggleCustomizeGame(): void {
    this.customizeGame = !this.customizeGame;
  }

  changeGameSettings() {
    const name = this.hostForm.controls.playerName.value;

    const config = new GameConfig();
    config.mode = this.hostForm.controls.mode.value;
    config.submode = this.hostForm.controls.submode.value;
    config.rounds = this.hostForm.controls.rounds.value;
    config.roundtime = this.hostForm.controls.roundtime.value * 1000;
    config.jokers = this.hostForm.controls.jokers.value;

    this.gameService
      .configure(name, config)
      .then(() => {
        this.toggleCustomizeGame();
        this.isSubmitted = true;
      })
      .catch(() => {
        this.messageService.presentErrorToast('Something went wrong &#128533;');
      });
  }

  start(): void {
    if (!this.gameStateService.areAllOtherPlayersReady()) {
      this.messageService
        .presentAlert('Not everyone is ready. Leave them behind?')
        .then((answer) => {
          if (answer) {
            this.doStart();
          }
        });
    } else {
      this.doStart();
    }
  }

  ready(ready: boolean): void {
    this.gameService
      .ready(ready)
      .then((readied) => {
        if (readied.player.ready) {
          this.messageService.presentToast('Waiting for host &#9203;');
        }
      })
      .catch(() => {
        this.messageService.presentErrorToast('Something went wrong &#128533;');
      });
  }

  openShareModal(): void {
    Share.canShare().then((canShareResult) => {
      if (canShareResult.value) {
        const invitor = this.gameStateService.getCurrentPlayer().name;
        Share.share({
          title: 'QES - ' + invitor + ' wants to quiz you',
          text: 'Share with your friends or enemies',
          url: this.joinUrl,
          dialogTitle: 'QES - ' + invitor + ' wants to quiz you',
        });
      } else {
        this.messageService.presentErrorToast('Sharing not supported on your device &#128533;');
      }
    });
  }

  openQrcodeModal(): void {
    document.getElementsByTagName('header')[0].style.zIndex = '0';

    this.qrcode.nativeElement.style.display = 'flex';
    const animation = this.animationController
      .create('qrcode-fadein')
      .addElement(this.qrcode.nativeElement)
      .duration(200)
      .easing('ease-out')
      .keyframes([
        { offset: 0, opacity: 0 },
        { offset: 1, opacity: 1 },
      ]);
    animation.play();
  }

  closeQrcodeModal(): void {
    const animation = this.animationController
      .create('qrcode-fadeout')
      .addElement(this.qrcode.nativeElement)
      .duration(200)
      .easing('ease-in')
      .keyframes([
        { offset: 0, opacity: 1 },
        { offset: 1, opacity: 0 },
      ]);
    animation.play().then(() => {
      this.qrcode.nativeElement.style.display = 'none';
      document.getElementsByTagName('header')[0].style.zIndex = '101';
    });
  }

  private doStart(): void {
    if (this.gameStateService.getGame().finished) {
      this.gameService.replay().catch(() => {
        this.messageService.presentErrorToast('Something went wrong &#128533;');
      });
    } else {
      this.gameService.start().catch(() => {
        this.messageService.presentErrorToast('Something went wrong &#128533;');
      });
    }
  }
}
