import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Answer, Round } from 'projects/models/src/public-api';

@Component({
  selector: 'app-answer-box',
  templateUrl: './answer-box.component.html',
  styleUrls: ['./answer-box.component.scss'],
})
export class AnswerBoxComponent {
  @Input() round: Round;
  @Output() answerSelected = new EventEmitter<Answer>();

  answerCharacters = ['A', 'B', 'C', 'D'];
  answers: Answer[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.round.currentValue) {
      this.updateRoundState(
        changes.round.currentValue as Round,
        changes.round.previousValue as Round
      );
    }
  }

  private updateRoundState(currentRound: Round, previousValue?: Round): void {
    this.answers = new Array();

    if (currentRound.question?.answers) {
      for (let answer of currentRound.question.answers) {
        const ext = {
          letter: this.answerCharacters[answer.id - 1],
          class: this.getAnswerButtonClass(answer),
        };
        this.answers.push(Object.assign(answer, ext));
      }
    }
  }

  selectAnswer(answer: Answer) {
    this.answerSelected.next(answer);
  }

  getAnswerButtonClass(answer: Answer): Object {
    let classes = {
      answerButtonCorrect: false,
      answerButtonSelected: false,
      answerButtonWrong: false,
      answerButtonHintCorrect: false,
      answerButtonHintSelected: false,
      answerButtonHintWrong: false,
    };

    // simple selection
    if (answer.selected) {
      classes.answerButtonSelected = true;
    }

    // running
    if (this.round.running) {
      // hints
      if (this.isJokerActive('50:50') || this.isJokerActive('seer')) {
        if (answer.correct === false) {
          classes.answerButtonHintWrong = true;
        } else {
          classes.answerButtonHintCorrect = true;
        }
      }
    }
    // !running
    else {
      // correct
      if (answer.correct === true) {
        classes.answerButtonCorrect = true;
      }
      // wrong
      else if (answer.correct === false) {
        classes.answerButtonWrong = true;
      }

      // show selection with hint only
      if (answer.selected === true) {
        classes.answerButtonSelected = false;
        classes.answerButtonHintSelected = true;
      }
    }

    return classes;
  }

  private isJokerActive(name: string): boolean {
    const jokers = this.round.jokers;
    if (jokers) {
      return jokers.find((j) => j.name.toLowerCase() == name) != null;
    }
    return false;
  }
}
