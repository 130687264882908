import { GameConfig } from './game-config.model';
import { Player } from './player.model';
import { Round } from './round.model';

class Game {
  id: string;
  running: boolean;
  finished: boolean;
  createdTime: string;
  startedTime: string;
  finishedTime: string;

  players: Array<Player>;
  spectators: Array<Player>;
  roundsPlayed: number;
  round: Round;
  config: GameConfig;
}

export { Game };
