<ion-content>
  <header>
    <ion-toolbar>
      <ion-title>
        <ion-icon name="lock-open-outline"></ion-icon>
        <ion-label class="category-title">Login</ion-label>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </header>

  <section>
    <ion-card>
      <ion-card-content class="join-game-content">
        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
          <ion-item lines="full">
            <ion-input formControlName="username" label="Username" labelPlacement="floating" type="text"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!loginForm.controls.username.valid  && (loginForm.controls.username.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter your username
          </div>

          <ion-item lines="full">
            <ion-input formControlName="password" label="Password" labelPlacement="floating" type="password"></ion-input>
          </ion-item>
          <div
            class="form--error-message"
            *ngIf="!loginForm.controls.password.valid  && (loginForm.controls.password.dirty || isSubmitted)"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            Enter your password
          </div>

          <button class="button-style ion-activatable" type="submit" expand="block">
            <span class="inner">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              <span>Login</span>
            </span>
            <ion-ripple-effect></ion-ripple-effect>
          </button>
        </form>

        <ion-item class="item--links">
          <ion-label class="ion-text-center">
            New?
            <a (click)="go2Register()">Register</a>
            |
            <a (click)="go2Forgot()">Forgot login?</a>
          </ion-label>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </section>
</ion-content>
