class Mode {
  id: string;
  name: string;
  image: string;
  color: string;
  submodes: Submode[];
}

class Submode {
  id: string;
  name: string;
  image: string;
  color: string;
}

export { Mode, Submode };
