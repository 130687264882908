export class Clientplatform {
  mobile: boolean;
  landscape: boolean;
  width: number;
  height: number;

  constructor(mobile: boolean, landscape: boolean, width: number, height: number) {
    this.mobile = mobile;
    this.landscape = landscape;
    this.width = width;
    this.height = height;
  }
}
