<ion-card class="game-card">
  <!-- Question View -->
  <app-question-box
    [gameConfig]="gameStateService.getGame().config"
    [round]="gameStateService.getRound()"
    [audioPlaying]="audioPlaying"
    [time]="roundTimer"
    (audioControl)="toggleAudio($event)"
  ></app-question-box>

  <!-- Answer View -->
  <app-answer-box
    [round]="gameStateService.getRound()"
    (answerSelected)="answer($event)"
  ></app-answer-box>

  <div class="shadow one"></div>
  <div class="shadow two"></div>
</ion-card>
