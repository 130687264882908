import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import {
  ApikeyInterceptor,
  DarkModeService,
  GameSettingsService,
  JwtInterceptor,
  PwaService,
  StartupService,
} from 'projects/services/src/public-api';
import { environment } from '../environments/environment';
import { pageTransition } from './app.animation';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { SharedModule } from './shared/shared.module';

import { FeedbackPageModule } from './feedback/feedback.module';
import { ForgotPageModule } from './forgot/forgot.module';
import { LoginPageModule } from './login/login.module';
import { RegisterPageModule } from './register/register.module';
import { ThanksPageModule } from './thanks/thanks.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GameHeaderComponent } from './game-header/game-header.component';
import { BrowseHeaderComponent } from './browse-header/browse-header.component';

export function startupServiceFactory(startupService: StartupService) {
  return function () {
    return startupService.initialize();
  };
}

export function pwaServiceFactory(pwaService: PwaService) {
  return function () {
    return Promise.resolve();
  };
}

export function gameSettingsServiceFactory(gameSettingsService: GameSettingsService) {
  return function () {
    return gameSettingsService.load();
  };
}

export function darkModeServiceFactory(darkModeService: DarkModeService) {
  return function () {
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BrowseHeaderComponent,
    GameHeaderComponent,
    FooterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true, navAnimation: pageTransition }),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    SharedModule,
    // modals
    FeedbackPageModule,
    ForgotPageModule,
    LoginPageModule,
    RegisterPageModule,
    ThanksPageModule,
  ],
  providers: [
    StartupService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApikeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: pwaServiceFactory,
      deps: [PwaService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: gameSettingsServiceFactory,
      deps: [GameSettingsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: darkModeServiceFactory,
      deps: [DarkModeService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
