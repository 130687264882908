import { readBuildInformation } from './environmentutil';

export const environment = {
  version: 'n/a',
  production: true,
  FRONTEND_URL: 'https://www.mobile-staging.play-qes.de/',
  SERVER_URL: 'https://www.backend-staging.play-qes.de/',
  APIKEY: '8SYX7YQjDAP6xdcaw1fQ0iaTW83IUO',

  features: {
    autorejoin: true,
    autodarkmode: true,
    coolplayernames: true,
    debug: 'qes:*',
  },
};

(async () => {
  environment.version = await readBuildInformation();
})();
