import { Component } from '@angular/core';
import { GameStateService } from 'projects/services/src/public-api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(public gameStateService: GameStateService) {}
}
