import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreloadSelectedModules implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.selectedForPreload(route) ? load() : EMPTY;
  }

  selectedForPreload(route): boolean {
    return route?.data?.preload;
  }
}
