export class Joker {
  id: number;
  category: JokerCategory;
  name: string;
  icon: string;
  config: JokerConfig;
  used: boolean;
}

export enum JokerCategory {
  single = 'single',
  round = 'round'
}

export class JokerConfig {
  name: string;
  score: string;
  dropratio: string;
}
